import * as React from "react";
import ServicesList from "../Section/ServicesList";
import Banner from "../Section/Banner";
import CardImage from "../Section/CardImage";
// add all Appel devices and their respective models here
interface ServiceData {
  [device: string]: {
    [version: string]: {
      [service: string]: number;
    };
  };
}
interface newServiceData {
  [device: string]: {
    [version: string]: {
      [versionNumber: string]: {
        [service: string]: number;
      };
    };
  };
}

const servicesData: newServiceData = {
  "iPhone": {
    "iPhone 6": {
      "6s": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "6s Plus": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
    },
    "iPhone 7": {
      "7": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "7 Plus": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
    },
    "iPhone 8": {
      "8": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "8 Plus": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
    },

    "iPhone SE": {
      "SE": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "SE (2. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "SE (3. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
    },
    "iPhone X": {
      "XS Max": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "XS": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "X": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "XR": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
    },
    "iPhone 11": {
      "Pro Max": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "Pro": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "11": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
    },
    "iPhone 12": {
      "Pro Max": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "Pro": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "12": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "12 mini": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
    },
    "iPhone 13": {
      "Pro Max": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "Pro": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "13": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "13 mini": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
    },
    "iPhone 14": {
      "Pro Max": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "Pro": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "14": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "14 Plus": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
    },
    "iPhone 15": {
      "15": {
        "Batteriewechsel": 10,
        "Rücksystemtausch ": 10,
        "defekte Lautsprecher": 10,
        "Display Tausch": 10,
        "Rückkamera Tausch": 10,
        "Wasserschaden": 10,
        "Stromzufuhr ": 10,
        "Wasser & Staubschutz": 10,
        "Weitere Schäden": 10,
        },
        
        "15 Pro": {
          "Batteriewechsel": 10,
          "Rücksystemtausch ": 10,
          "defekte Lautsprecher": 10,
          "Display Tausch": 10,
          "Rückkamera Tausch": 10,
          "Wasserschaden": 10,
          "Stromzufuhr ": 10,
          "Wasser & Staubschutz": 10,
          "Weitere Schäden": 10,
      },
      "15 Plus": {
        "Batteriewechsel": 10,
        "Rücksystemtausch ": 10,
        "defekte Lautsprecher": 10,
        "Display Tausch": 10,
        "Rückkamera Tausch": 10,
        "Wasserschaden": 10,
        "Stromzufuhr ": 10,
        "Wasser & Staubschutz": 10,
        "Weitere Schäden": 10,
              },
      "Pro Max": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },



    },
  },
  "Mac & mehr": {
    "MacBook Air": {
      "MacBook Air (11-inch, Early 2015)": {
        "Display Tausch": 50,
        "Topcase Tausch": 80,
        "Akku Tausch": 30,
        "Mainboard Tausch": 30,
        "I/O Board Tausch ": 30,
        "Trackpad Tausch ": 30,
        "Bottomcase Tausch ": 30,
        "Wasser Schaden": 30,
        Sonstige: 30,
      },
      "MacBook Air (13-inch, Early 2015)": {
        "Display Tausch": 50,
        "Topcase Tausch": 80,
        "Akku Tausch": 30,
        "Mainboard Tausch": 30,
        "I/O Board Tausch ": 30,
        "Trackpad Tausch ": 30,
        "Bottomcase Tausch ": 30,
        "Wasser Schaden": 30,
        Sonstige: 30,
      },
      "MacBook Air (13-inch, 2017)": {
        "Display Tausch": 50,
        "Topcase Tausch": 80,
        "Akku Tausch": 30,
        "Mainboard Tausch": 30,
        "I/O Board Tausch ": 30,
        "Trackpad Tausch ": 30,
        "Bottomcase Tausch ": 30,
        "Wasser Schaden": 30,
        Sonstige: 30,
      },
      "MacBook Air (Retina, 13-inch, 2018)": {
        "Display Tausch": 50,
        "Topcase Tausch": 80,
        "Akku Tausch": 30,
        "Mainboard Tausch": 30,
        "I/O Board Tausch ": 30,
        "Trackpad Tausch ": 30,
        "Bottomcase Tausch ": 30,
        "Wasser Schaden": 30,
        Sonstige: 30,
      },
      "MacBook Air (Retina, 13-inch, 2019)": {
        "Display Tausch": 50,
        "Topcase Tausch": 80,
        "Akku Tausch": 30,
        "Mainboard Tausch": 30,
        "I/O Board Tausch ": 30,
        "Trackpad Tausch ": 30,
        "Bottomcase Tausch ": 30,
        "Wasser Schaden": 30,
        Sonstige: 30,
      },
      "MacBook Air (Retina, 13-inch, 2020)": {
        "Display Tausch": 50,
        "Topcase Tausch": 80,
        "Akku Tausch": 30,
        "Mainboard Tausch": 30,
        "I/O Board Tausch ": 30,
        "Trackpad Tausch ": 30,
        "Bottomcase Tausch ": 30,
        "Wasser Schaden": 30,
        Sonstige: 30,
      },
      "MacBook Air (M2, 2022)": {
        "Display Tausch": 50,
        "Topcase Tausch": 80,
        "Akku Tausch": 30,
        "Mainboard Tausch": 30,
        "I/O Board Tausch ": 30,
        "Trackpad Tausch ": 30,
        "Bottomcase Tausch ": 30,
        "Wasser Schaden": 30,
        Sonstige: 30,
      },
    },
    "MacBook Pro": {
      "MacBook Pro 13": {
        "Display Tausch": 50,
        "Topcase Tausch": 80,
        "Akku Tausch": 30,
        "Mainboard Tausch": 30,
        "I/O Board Tausch ": 30,
        "Trackpad Tausch ": 30,
        "Bottomcase Tausch ": 30,
        "Wasser Schaden": 30,
        Sonstige: 30,
      },
      "MacBook Pro 14": {
        "Display Tausch": 50,
        "Topcase Tausch": 80,
        "Akku Tausch": 30,
        "Mainboard Tausch": 30,
        "I/O Board Tausch ": 30,
        "Trackpad Tausch ": 30,
        "Bottomcase Tausch ": 30,
        "Wasser Schaden": 30,
        Sonstige: 30,
      },
      "MacBook Pro 15": {
        "Display Tausch": 50,
        "Topcase Tausch": 80,
        "Akku Tausch": 30,
        "Mainboard Tausch": 30,
        "I/O Board Tausch ": 30,
        "Trackpad Tausch ": 30,
        "Bottomcase Tausch ": 30,
        "Wasser Schaden": 30,
        Sonstige: 30,
      },
      "MacBook Pro 16": {
        "Display Tausch": 50,
        "Topcase Tausch": 80,
        "Akku Tausch": 30,
        "Mainboard Tausch": 30,
        "I/O Board Tausch ": 30,
        "Trackpad Tausch ": 30,
        "Bottomcase Tausch ": 30,
        "Wasser Schaden": 30,
        Sonstige: 30,
      },
    },

    iMac: {
      "iMac 21": {
        "Upgrade ": 30,
        "Akku/ Netztel": 30,
        "Topcase Tausch": 80,
        Sonstige: 30,
      },
      "iMac 24": {
        "Upgrade ": 30,
        "Akku/ Netztel": 30,
        "Topcase Tausch": 80,
        Sonstige: 30,
      },
      "iMac 27": {
        "Upgrade ": 30,
        "Akku/ Netztel": 30,
        "Topcase Tausch": 80,
        Sonstige: 30,
      },
    },
    "Mac Pro": {
      "Mac Pro 2013": {
        "Graphig Karte Upgrade ": 30,
        "Memmory Upgrade ": 30,
        "Daten Verwaltung ": 30,
        "Software Solution": 30,
        Sonstige: 30,
      },
      "Mac Pro 2019": {
        "Graphig Karte Upgrade ": 30,
        "Memmory Upgrade ": 30,
        "Daten Verwaltung ": 30,
        "Software Solution": 30,
        Sonstige: 30,
      },
    },
    "Mac mini": {
      "Mac mini 2014": {
        "Mainboard Tausch": 30,
        Netztel: 30,
        "Daten Upgrade ": 30,
        Sonstige: 30,
      },
      "Mac mini 2018": {
        "Mainboard Tausch": 30,
        Netztel: 30,
        "Daten Upgrade ": 30,
        Sonstige: 30,
      },
    },
    "Mac Studio": {
      "Mac Studio M1 MAX": {
        "Mainboard Tausch": 30,
        Netztel: 30,
        "Daten Upgrade ": 30,
        Sonstige: 30,
      },
      "Mac Studio M1 Ultra": {
        "Mainboard Tausch": 30,
        Netztel: 30,
        "Daten Upgrade ": 30,
        Sonstige: 30,
      },
      "Mac Studio M2 Max": {
        "Mainboard Tausch": 30,
        Netztel: 30,
        "Daten Upgrade ": 30,
        Sonstige: 30,
      },
      "Mac Studio M2 Ultra": {
        "Mainboard Tausch": 30,
        Netztel: 30,
        "Daten Upgrade ": 30,
        Sonstige: 30,
      },
    },
    "Mac Display": {
      "Mac Display 2019": {
        "Mainboard Tausch": 30,
        Netztel: 30,
        "Daten Upgrade ": 30,
        Sonstige: 30,
      },
    },

  },
  "iPad": {
    "iPad mini": {
      "iPad mini 4 Wi-Fi": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad mini 4 Wi-Fi + Cellular": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad mini 5 Wi-Fi": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad mini 5 Wi-Fi + Cellular": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad mini 6 Wi-Fi": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad mini 6 Wi-Fi + Cellular": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
    },
    "iPad": {
      "iPad 6 Wi-Fi": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad 6 Wi-Fi + Cellular": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad 7 Wi-Fi": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad 7 Wi-Fi + Cellular": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad 8 Wi-Fi": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad 8 Wi-Fi + Cellular": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad 9 Wi-Fi": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad 9 Wi-Fi + Cellular": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad 10 Wi-Fi": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad 10 Wi-Fi + Cellular": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
    },
    "iPad Air": {
      "iPad Air 3 Wi-Fi": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Air 3 Wi-Fi + Cellular": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Air 4 Wi-Fi": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Air 4 Wi-Fi + Cellular": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Air 5 Wi-Fi": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Air 5 Wi-Fi + Cellular": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
    },
    "iPad Pro 10,5": {
      "iPad Pro 10,5 Wi-Fi": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 10,5 Wi-Fi + Cellular": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
    },
    "iPad Pro 11": {
      "iPad Pro 11 Wi-Fi": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 11 Wi-Fi + Cellular": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 11 Wi-Fi (2. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 11 Wi-Fi + Cellular (2. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 11 Wi-Fi (3. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 11 Wi-Fi + Cellular (3. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 11 Wi-Fi (4. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 11 Wi-Fi + Cellular (4. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
    },
    "iPad Pro 12,9": {
      "iPad Pro 12,9 Wi-Fi (2. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 12,9 Wi-Fi + Cellular (2. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 12,9 Wi-Fi (3. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 12,9 Wi-Fi + Cellular (3. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 12,9 Wi-Fi (4. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 12,9 Wi-Fi + Cellular (4. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 12,9 Wi-Fi (5. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 12,9 Wi-Fi + Cellular (5. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 12,9 Wi-Fi (6. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
      "iPad Pro 12,9 Wi-Fi + Cellular (6. Gen.)": {
"Batteriewechsel": 10,
"Rücksystemtausch ": 10,
"defekte Lautsprecher": 10,
"Display Tausch": 10,
"Rückkamera Tausch": 10,
"Wasserschaden": 10,
"Stromzufuhr ": 10,
"Wasser & Staubschutz": 10,
"Weitere Schäden": 10,
      },
    },
  },
"Laptop": {
      "Acer": {
        "E Series": {
          "Display Tausch": 50,
          "Topcase Tausch": 80,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "I/O Board Tausch": 30,
          "Trackpad Tausch": 30,
          "Bottomcase Tausch": 30,
          "Wasser Schaden": 30,
          "Sonstige": 30
        },
        "Aspire": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Swift 3": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Predator Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Spin Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "ConceptD Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
        },

      "Dell": {
        "Ultrabooks": {
          "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Alienware": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Dell G": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Latitude": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Precision":{
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "XPS 2-in-1":{
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Chromebook 11":{
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          }
        },
      "HP": {
        "Spectre Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Omen Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Pavilion Gaming": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "EliteBook Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "ProBook Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "ZBook Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Envy Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Chromebook Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          }
        },
      "Lenovo": {
          " Yoga Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Legion Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "ThinkPad Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "ThinkBook Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Flex Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
        },
      "MSI": {
        "GT Series": {
             "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "GE Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "GP Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Prestige Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Summit Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Creator Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Summit E13 Flip (2021-2023)":{
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          
          }
        },
        "Asus": {
          "ZenBook Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "VivoBook Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "ROG Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "TUF Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "ExpertBook Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Chromebook Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },

        },
        "Fujitsu": {
          "Lifebook Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Stylistic Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Celsius Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Esprimo Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
        },
        "Toshiba": {
          "Satellite Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Portégé Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Tecra Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
          "Qosmio Series": {
            "Display Tausch": 50,
            "Topcase Tausch": 80,
            "Akku Tausch": 30,
            "Mainboard Tausch": 30,
            "I/O Board Tausch": 30,
            "Trackpad Tausch": 30,
            "Bottomcase Tausch": 30,
            "Wasser Schaden": 30,
            "Sonstige": 30
          },
        },


      },
"SmartPhone": {
    "Samsung": {
      "Galaxy S": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "Galaxy A": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "Galaxy Note": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "Galaxy M": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "Galaxy Z": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "Galaxy XCover": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "Galaxy F": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "Galaxy J": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "Galaxy Tab": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
      },
      "Huawei": {
        "P Series": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "Mate Series": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "Nova Series": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "Y Series": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "Honor Series": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
      },
      "Nokia": {
        "X Series": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "G Series": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "C Series": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "N Series": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
      },
      "Sony": {
        "Xperia 1": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "Xperia 5": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "Xperia 10": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "Xperia L": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
      },
      "LG": {
        "G Series": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "V Series": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "K Series": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "Q Series": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
      },
      "OnePlus": {
        "OnePlus 9 Pro": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "OnePlus 9": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "OnePlus 8T": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
        "OnePlus 8 Pro": {
          "Display Tausch": 50,
          "Akku Tausch": 30,
          "Mainboard Tausch": 30,
          "Kamera Tausch": 30,
          "Lautsprecher Tausch": 30,
          "Wasserschaden": 30,
          "Stromzufuhr": 30,
          "Wasser & Staubschutz": 30,
          "Weitere Schäden": 30
        },
      }
    }
  };


export interface IServicesProps {}

export interface IServicesState {
  dataFilter: newServiceData;
  selecedDevice: string;
  selectedVersion: string;
  selectedVersionNumber: string;
  selectedService: string;
  prise: number;
  showForm: boolean;
  note: string;
  name: string;
  lastName: string;
  phone: string;
  email: string;
  street: string;
  houseNumber: string;
  zip: string;
  city: string;
  succesMsg: boolean;
  serialNumber: string;
  consent: boolean;
  msg: string;
  isPost: boolean;
  
}

export default class Services extends React.Component<
  IServicesProps,
  IServicesState
> {
  constructor(props: IServicesProps) {
    super(props);

    this.state = {
      dataFilter: servicesData,
      selecedDevice: "",
      selectedVersion: "",
      selectedVersionNumber: "",
      selectedService: "",
      prise: 0,
      showForm: false,
      note: "",
      name: "",
      lastName: "",
      phone: "",
      email: "",
      street: "",
      houseNumber: "",
      zip: "",
      city: "",
      succesMsg: false,
      serialNumber: "",
      consent: false,
      msg: "",
      isPost: false,
    };
  }

  private handleSendEmail(): void {
    const {
      selecedDevice,
      selectedVersion,
      selectedVersionNumber,
      selectedService,
      name,
      lastName,
      phone,
      email,
      street,
      houseNumber,
      zip,
      city,
      note,
      serialNumber,
      isPost,
    } = this.state;
    const data = {
      selecedDevice,
      selectedVersion,
      selectedVersionNumber,
      selectedService,
      name,
      lastName,
      phone,
      email,
      street,
      houseNumber,
      zip,
      city,
      note,
      serialNumber,
      isPost,
    };
    fetch("https://www.IdlibCom.at/api/send_email.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        // if success
        if (data.success) {
          this.setState({
            name: "",
            lastName: "",
            phone: "",
            email: "",
            street: "",
            houseNumber: "",
            zip: "",
            city: "",
            note: "",
            serialNumber: "",
            showForm: false,
            succesMsg: true,
            isPost: false,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    this.setState({ succesMsg: true,
      name: "",
      lastName: "",
      phone: "",
      email: "",
      street: "",
      houseNumber: "",
      zip: "",
      city: "",
      note: "",
      serialNumber: "",
      showForm: false,
      isPost: false,
    });

    window.scrollTo(0, 0);
  }

  componentDidUpdate(
    prevProps: Readonly<IServicesProps>,
    prevState: Readonly<IServicesState>,
    snapshot?: any
  ): void {
    // set success message to false after 5 seconds
    if (this.state.succesMsg) {
      setTimeout(() => {
        this.setState({ succesMsg: false });
      }, 5000);
    }

  }

  componentDidMount(): void {}
   backgroundImageUrl = "../../../img/service/bg.png";

  public render() {
    return (
      <div className="">
        <Banner isHome={false} pageName="SERVICE & REPARATUR" color="white" />
      {this.state.succesMsg &&
          <div className="w-full bg-green-500 text-white text-center py-2">
            <p>Vielen Dank für Ihre Anfrage. Wir werden uns so schnell wie möglich bei Ihnen melden.</p>
          </div>
            }
        <div
                  style={{
                    backgroundImage: `url(${this.backgroundImageUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    
        
                  }}
        >
        <div className={`flex flex-wrap gap-6 mx-auto text-center justify-center w-full  sm:w-2/3 md:w-full lg:w-full xl:w-3/4 py-40`}

        >
          {this.state.selecedDevice === "" ? (
            Object.keys(this.state.dataFilter).map((category) => (
              <CardImage
                key={category}
                name={category}
                isWithimg={true}
                onClick={() => {
                  this.setState({ selecedDevice: category });
                  window.scrollTo({ top: 500, behavior: "smooth" });
                }}
              />
            ))
          ) : (
            <CardImage
              name={this.state.selecedDevice}
              isWithimg={true}
              onClick={() => {
                this.setState({
                  selecedDevice: "",
                  selectedVersion: "",
                  selectedVersionNumber: "",
                  selectedService: "",
                  prise: 0,
                  showForm: false,
                });
              }}
            />
          )}
        </div>
        </div>

        <div className={`${this.state.selecedDevice === "" && this.state.selectedVersion === "" ? " hidden  ":"  "} flex flex-wrap gap-6 mx-auto text-center justify-center w-full p-10 sm:w-5/6 xl:w-3/4`}>
          {this.state.selecedDevice !== "" ? (
            this.state.selectedVersion === "" ? (
              Object.keys(this.state.dataFilter[this.state.selecedDevice]).map(
                (version) => (
                  <CardImage
                    key={version}
                    name={version}
                    isWithimg={true}
                    onClick={() => {
                      this.setState({ selectedVersion: version });
                      window.scrollTo({ top: 400, behavior: "smooth" });
                    }}
                  />
                )
              )
            ) : (
              <CardImage
                name={this.state.selectedVersion}
                isWithimg={true}
                onClick={() => {
                  this.setState({
                    selectedVersion: "",
                    selectedVersionNumber: "",
                    selectedService: "",
                    prise: 0,
                    showForm: false,
                  });
                  window.scrollTo({ top: 200, behavior: "smooth" });
                }}
              />
            )
          ) : (
            <></>
          )}
        </div>
        <div className={`w-1/2 my-3 m-auto  ${this.state.selecedDevice !== "" && this.state.selectedVersion !== "" && this.state.selectedVersionNumber === "" ? "   ":"  hidden"}`}>
          <img src="../../../img/service/mfk.png" alt="" className="text-center m-auto w-1/2 my-3"/>
        </div>
        <div className={`${this.state.selecedDevice !== "" && this.state.selectedVersion !== "" && this.state.selectedVersionNumber === "" ? "":"hidden "} flex flex-wrap gap-6 mx-auto text-center justify-center w-full p-10 sm:w-5/6 xl:w-full`}>
          {this.state.selecedDevice !== "" &&
          this.state.selectedVersion !== "" ? (
            this.state.selectedVersionNumber === "" ? (
              Object.keys(
                this.state.dataFilter[this.state.selecedDevice][
                  this.state.selectedVersion
                ]
              ).map((VersionNumber) => (
                <CardImage
                  key={VersionNumber}
                  name={VersionNumber}
                  isWithimg={false}
                  onClick={() => {
                    this.setState({ selectedVersionNumber: VersionNumber });
                    window.scrollTo({ top: 400, behavior: "smooth" });
                  }}
                />
              ))
            ) : (
              <CardImage
                name={this.state.selectedVersionNumber}
                isWithimg={false}
                onClick={() => {
                  this.setState({
                    selectedVersionNumber: "",
                    selectedService: "",
                    prise: 0,
                    showForm: false,
                  });
                }}
              />
            )
          ) : (
            <></>
          )}
        </div>
        <div className={` ${this.state.selecedDevice !== "" && this.state.selectedVersion !== "" && this.state.selectedVersionNumber !== "" ? "":" hidden " } flex flex-wrap gap-6 mx-auto text-center justify-center w-full p-10 sm:w-full xl:w-full`}>
          {this.state.selecedDevice !== "" &&
          this.state.selectedVersion !== "" ? (
            this.state.selectedVersionNumber !== "" ? (
              this.state.selectedService === "" ? (
                Object.keys(
                  this.state.dataFilter[this.state.selecedDevice][
                    this.state.selectedVersion
                  ][this.state.selectedVersionNumber]
                ).map((service) => (
                  <CardImage
                    key={service}
                    name={service}
                    isWithimg={false}
                    onClick={() => {
                      this.setState({
                        selectedService: service,
                        prise:
                          this.state.dataFilter[this.state.selecedDevice][
                            this.state.selectedVersion
                          ][this.state.selectedVersionNumber][service],
                      });
                      window.scrollTo({ top: 400, behavior: "smooth" });
                    }}
                  />
                ))
              ) : (
                <CardImage
                  name={this.state.selectedService}
                  isWithimg={false}
                  onClick={() => {
                    this.setState({
                      selectedService: "",
                      prise: 0,
                      showForm: false,
                    });
                  }}
                />
              )
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </div>

        {/**show prise */}
        <div className={`${this.state.prise !== 0 ?" ":"hidden "} flex flex-wrap gap-6 mx-auto text-center justify-center w-full p-10 sm:w-5/6 xl:w-3/4`}>
          {this.state.prise !== 0 ? (
            <div>
              {/** <h1 className="text-2xl font-bold text-gray-800">Prise ab: {this.state.prise}€</h1> */}
              <button
                className="bg-red-800 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => {
                  this.setState({ showForm: true });
                  window.scrollTo({ top: 1500, behavior: "smooth" });
                }}
              >
                JETZT ANFRAGE SENDEN
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>

        {/**show form */}
        <div className={`${this.state.showForm ?"":"hidden "} flex flex-wrap gap-6 mx-auto text-center justify-center w-full p-10 sm:w-5/6 xl:w-3/4`}>
          {this.state.showForm ? (
            <div className="items-center justify-center w-[90%] m-auto shadow-lg rounded-lg p-4">
              <div className="my-3">
                <p className="text-center text-2xl font-bold">
                  Adresse and Info
                </p>
                <p className="text-center text-gray-500">
                  Bitte füllen Sie alle Felder aus
                </p>
                <p className="text-center text-gray-500">* Pflichtfelder</p>
              </div>
              <div>
                <input
                  type="text"
                  onChange={(e) => this.setState({ name: e.target.value })}
                  placeholder="Vorname *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                  required
                />
              </div>
              <div>
                <input
                  type="text"
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                  placeholder="Nachname *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                />
              </div>
              <div>
                <input
                  type="text"
                  onChange={(e) => this.setState({ phone: e.target.value })}
                  placeholder="Telefonnummer *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                />
              </div>
              <div>
                <input
                  type="mail"
                  onChange={(e) => this.setState({ email: e.target.value })}
                  placeholder="Email *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                />
              </div>
              <div>
                <input
                  type="text"
                  onChange={(e) => this.setState({ street: e.target.value })}
                  placeholder="Straße *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                />
              </div>
              <div>
                <input
                  type="text"
                  onChange={(e) =>
                    this.setState({ houseNumber: e.target.value })
                  }
                  placeholder="Hausnummer *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                />
              </div>
              <div>
                <input
                  type="text"
                  onChange={(e) => this.setState({ zip: e.target.value })}
                  placeholder="PLZ *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                />
              </div>
              <div>
                <input
                  type="text"
                  onChange={(e) => this.setState({ city: e.target.value })}
                  placeholder="Ort *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                />
              </div>

              <div>
                <input
                  type="text"
                  onChange={(e) =>
                    this.setState({ serialNumber: e.target.value })
                  }
                  placeholder="Seriennummer *"
                  className="border-b-2 border-gray-300 p-2 w-full my-3 outline-none"
                />
              </div>
              <div className="flex items-center  w-full cursor-pointer my-6 lg:gap-6 lg:flex-col-2 flex-col-1">
                <input
                  type="checkbox"
                  id="isPost"
                  name="isPost"
                  value={this.state.consent.toString()}
                  onChange={(e) =>
                    this.setState((prevState) => ({
                      isPost: !prevState.isPost
                    }))
                  }
                  
                />
                <label htmlFor="isPost" className="text-gray-500">
                  Gerät per Post einsenden
                </label>
              </div>
              <div className="flex items-center  w-full cursor-pointer my-6 lg:gap-6 lg:flex-col-2 flex-col-1">
                <input
                  type="checkbox"
                  id="consent"
                  name="consent"
                  value={this.state.consent.toString()}
                  onChange={(e) =>
                    this.setState((prevState) => ({
                      consent: !prevState.consent
                    }))
                  }
                  required
                />
                <label htmlFor="consent" className="text-gray-500">
                  Ich bin damit einverstanden, dass meine Daten gespeichert und
                  zur Kontaktaufnahme verwendet werden.
                </label>
              </div>
              <div className="flex lg:flex-1 w-full cursor-pointer my-6 mx-auto lg:gap-6 flex-col">
                <button
                  disabled={
                    this.state.name === "" ||
                    this.state.lastName === "" ||
                    this.state.phone === "" ||
                    this.state.email === "" ||
                    this.state.street === "" ||
                    this.state.houseNumber === "" ||
                    this.state.zip === "" ||
                    this.state.city === "" ||
                    this.state.serialNumber === "" ||
                    !this.state.consent
                  }
                  onClick={() => {
                    this.handleSendEmail();
                  }}
                  className="bg-red-800 text-white font-semibold hover:bg-red-700 py-2 px-4  hover:border-transparent rounded w-1/2 mx-auto my-3
                          disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Senden
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}
