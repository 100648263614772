import * as React from "react";
import { NavLink } from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.css';
import Worksteps from "../Section/Worksteps";
import CarouselTestimonial from "../Section/CarouselTestimonial";
import Cookiescomponent from "../Section/Cookiescomponent";
import FeatureSectionColorDesign from "../Section/FeatureSectionColorDesign";
import FeatureHighlightsSection from "../Section/FeatureHighlightsSection";
import GeraeteRepariert from "../Section/GeraeteRepariert";
import SalesSection from "../Section/SalesSection";


export interface IHomeProps {}

export interface IHomeState {}

export default class Home extends React.Component<IHomeProps, IHomeState> {
  constructor(props: IHomeProps) {
    super(props);

    this.state = {};
  }
  scrollToSecondSection = () => {
    const secondSection = document.querySelector(".second-section");
    if (secondSection) {
      secondSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  public render() {
    return (
      <div className="">
        
        <div
          className={`relative z-10 overflow-hidden bg-white`} >
                      {/* <!-- ====== Hero Section Start 
                        <div id="home" className="relative overflow-hidden bg-gray-50 pt-[120px] md:pt-[130px]" >
            <div className="container">
              <div className="-mx-4 flex flex-wrap items-center">
                <div className="w-full px-4">
                  <div
                    className="hero-content wow fadeInUp  text-center"
                    data-wow-delay=".2s">
                    <h1 className="mb-6 text-3xl font-bold leading-snug text-black sm:text-4xl sm:leading-snug lg:text-5xl lg:leading-[1.2] font-[roboto,sans-serif] ">
                    Willkommen bei<span className="text-gray-700"> <strong>IdlibCom!</strong> </span>
                    </h1>
                    <h1 className="font-bold leading-snug text-black sm:text-4xl sm:leading-snug lg:text-2xl lg:leading-[1.2] uppercase font-[roboto,sans-serif]">

                    IHR SPEZIALIST FÜR MAC UND MEHR
                    </h1>
                    <p className="text-[#000] uppercase text-xl font-[roboto,sans-serif]">OFFIZIELLER Appel und Windows ZERTIFIZIERTER REPARATUR PROVIDER</p>
                  </div>
                </div>
              </div>
            </div>
          </div>-->*/}
        
          {/*  <!-- ====== Hero Section End -->*/}
          {/* <!-- ====== Hero Section Start -->*/}
        
          {/*  <!-- ====== Hero Section End -->*/}


          <div className=" flex flex-col justify-center items-center bg-gray-50 h-16">
            {/* First Section */}

            {/* Down Arrow
                   <div className="mt-8">
              <svg
                onClick={this.scrollToSecondSection}
                className="animate-bounce w-6 h-6 m-auto cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="#fff"
                  d="M12 21l-8-8m8 8l8-8m-8 8V3"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div> */}
     
          </div>



        </div>
        <section className="bg-white dark:bg-gray-900">
    <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div className="mr-auto place-self-center lg:col-span-7">
          <p className="text-lg font-light text-gray-500 lg:mb-1 md:text-xl lg:text-2xl dark:text-black">
          Smartphone, Tablet oder Computer kaputt?
            </p>
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white"> Wir können das reparieren</h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            Schnell und einfach reparieren wir Ihr Gerät. Wir bieten eine schnelle und professionelle Reparatur für alle Arten von Geräten.
              </p>
            <NavLink 
            to="/services"
            className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900 bg-red-800">
                Leg los
                <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </NavLink>
            <NavLink 
            to="/contact"
             className="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
                Kontakt aufnehmen
            </NavLink> 
        </div>
        <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/phone-mockup.png" alt="mockup"/>
        </div>                
    </div>
</section>


          {/*  <!-- ====== Hero Section End -->*/}
          {/*  <!-- ====== Features Section Start --> */}
          <section  className="mt-6">
            <div className="container">
              <div className="-mx-4 flex flex-wrap">
                <div className="w-full px-4">
                  <div className="mb-12 mx-auto lg:mb-[70px] text-center">

                    <h2 className="mb-3 text-3xl font-bold text-dark sm:text-4xl md:text-[40px] md:leading-[1.2]">
                    Wir bieten die besten Dienstleistungen
                    </h2>
                    <p className="text-base text-body-color">
                      Wir sind bestrebt, unseren Kunden Außergewöhnlichen Service
                      und unseren Mitarbeitern die beste
                      Ausbildung zu bieten.
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </section>
          {/*  <!-- ====== Features Section End*/}
                        <div className="flex flex-wrap mx-3">
                <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                  <div
                    className="wow fadeInUp group mb-12 bg-white text-center"
                    data-wow-delay=".1s"
                  >
                    <div className="relative z-10 mb-10 flex h-[70px] w-[70px] items-center justify-center rounded-[14px] bg-red-800 mx-auto">
                      <span className="absolute top-0 left-0 -z-[1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-[14px] bg-red-800 bg-opacity-20 duration-300 group-hover:rotate-45"></span>
                      <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30.5801 8.30514H27.9926C28.6113 7.85514 29.1176 7.34889 29.3426 6.73014C29.6801 5.88639 29.6801 4.48014 27.9363 2.84889C26.0801 1.04889 24.3926 1.04889 23.3238 1.33014C20.9051 1.94889 19.2738 4.76139 18.3738 6.78639C17.4738 4.76139 15.8426 2.00514 13.4238 1.33014C12.3551 1.04889 10.6676 1.10514 8.81133 2.84889C7.06758 4.53639 7.12383 5.88639 7.40508 6.73014C7.63008 7.34889 8.13633 7.85514 8.75508 8.30514H5.71758C4.08633 8.30514 2.73633 9.65514 2.73633 11.2864V14.9989C2.73633 16.5739 4.03008 17.8676 5.60508 17.9239V31.6489C5.60508 33.5614 7.18008 35.1926 9.14883 35.1926H27.5426C29.4551 35.1926 31.0863 33.6176 31.0863 31.6489V17.8676C32.4926 17.6426 33.5613 16.4051 33.5613 14.9426V11.2301C33.5613 9.59889 32.2113 8.30514 30.5801 8.30514ZM23.9426 3.69264C23.9988 3.69264 24.1676 3.63639 24.3363 3.63639C24.7301 3.63639 25.3488 3.80514 26.1926 4.59264C26.8676 5.21139 27.0363 5.66139 26.9801 5.77389C26.6988 6.56139 23.8863 7.40514 20.6801 7.74264C21.4676 5.99889 22.6488 4.03014 23.9426 3.69264ZM10.4988 4.64889C11.3426 3.86139 11.9613 3.69264 12.3551 3.69264C12.5238 3.69264 12.6363 3.74889 12.7488 3.74889C14.0426 4.08639 15.2801 5.99889 16.0676 7.79889C12.8613 7.46139 10.0488 6.61764 9.76758 5.83014C9.71133 5.66139 9.88008 5.26764 10.4988 4.64889ZM5.26758 14.9426V11.2301C5.26758 11.0051 5.43633 10.7801 5.71758 10.7801H30.5801C30.8051 10.7801 31.0301 10.9489 31.0301 11.2301V14.9426C31.0301 15.1676 30.8613 15.3926 30.5801 15.3926H5.71758C5.49258 15.3926 5.26758 15.2239 5.26758 14.9426ZM27.5426 32.6614H9.14883C8.58633 32.6614 8.13633 32.2114 8.13633 31.6489V17.9239H28.4988V31.6489C28.5551 32.2114 28.1051 32.6614 27.5426 32.6614Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <h3 className="mb-3 text-xl font-bold text-dark">
                    Batteriewechsel 
                    </h3>
                    <p className="mb-8 text-body-color lg:mb-9">
                    Austausch der Batterie für verbesserte Leistung und längere Akkulaufzeit.
                    </p>
                  </div>
                </div>
                <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                  <div
                    className="wow fadeInUp group mb-12 bg-white text-center"
                    data-wow-delay=".15s"
                  >
                    <div className="relative z-10 mb-10 flex h-[70px] w-[70px] items-center justify-center rounded-[14px] bg-red-800 mx-auto">
                      <span className="absolute top-0 left-0 -z-[1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-[14px] bg-red-800 bg-opacity-20 duration-300 group-hover:rotate-45"></span>
                      <svg
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30.5998 1.01245H5.39981C2.98105 1.01245 0.956055 2.9812 0.956055 5.4562V30.6562C0.956055 33.075 2.9248 35.0437 5.39981 35.0437H30.5998C33.0186 35.0437 34.9873 33.075 34.9873 30.6562V5.39995C34.9873 2.9812 33.0186 1.01245 30.5998 1.01245ZM5.39981 3.48745H30.5998C31.6123 3.48745 32.4561 4.3312 32.4561 5.39995V11.1937H3.4873V5.39995C3.4873 4.38745 4.38731 3.48745 5.39981 3.48745ZM3.4873 30.6V13.725H23.0623V32.5125H5.39981C4.38731 32.5125 3.4873 31.6125 3.4873 30.6ZM30.5998 32.5125H25.5373V13.725H32.4561V30.6C32.5123 31.6125 31.6123 32.5125 30.5998 32.5125Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <h3 className="mb-3 text-xl font-bold text-dark">
                    Softwareaktualisierung 
                    </h3>
                    <p className="mb-8 text-body-color lg:mb-9">
                    Regelmäßige Updates, um die neuesten Funktionen und Sicherheitsverbesserungen zu gewährleisten.
                    </p>
                    
                  </div>
                </div>
                <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                  <div
                    className="wow fadeInUp group mb-12 bg-white text-center"
                    data-wow-delay=".2s"
                  >
                    <div className="relative z-10 mb-10 flex h-[70px] w-[70px] items-center justify-center rounded-[14px] bg-red-800 mx-auto">
                      <span className="absolute top-0 left-0 -z-[1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-[14px] bg-red-800 bg-opacity-20 duration-300 group-hover:rotate-45"></span>
                      <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M33.5613 21.4677L31.3675 20.1177C30.805 19.7239 30.0175 19.9489 29.6238 20.5114C29.23 21.1302 29.455 21.8614 30.0175 22.2552L31.48 23.2114L18.1488 31.5927L4.76127 23.2114L6.22377 22.2552C6.84252 21.8614 7.01127 21.0739 6.61752 20.5114C6.22377 19.8927 5.43627 19.7239 4.87377 20.1177L2.68002 21.4677C2.11752 21.8614 1.72377 22.4802 1.72377 23.1552C1.72377 23.8302 2.06127 24.5052 2.68002 24.8427L17.08 33.8989C17.4175 34.1239 17.755 34.1802 18.1488 34.1802C18.5425 34.1802 18.88 34.0677 19.2175 33.8989L33.5613 24.8989C34.1238 24.5052 34.5175 23.8864 34.5175 23.2114C34.5175 22.5364 34.18 21.8614 33.5613 21.4677Z"
                          fill="white"
                        />
                        <path
                          d="M20.1175 20.4552L18.1488 21.6364L16.18 20.3989C15.5613 20.0052 14.83 20.2302 14.4363 20.7927C14.0425 21.4114 14.2675 22.1427 14.83 22.5364L17.4738 24.1677C17.6988 24.2802 17.9238 24.3364 18.1488 24.3364C18.3738 24.3364 18.5988 24.2802 18.8238 24.1677L21.4675 22.5364C22.0863 22.1427 22.255 21.3552 21.8613 20.7927C21.4675 20.2302 20.68 20.0614 20.1175 20.4552Z"
                          fill="white"
                        />
                        <path
                          d="M7.74252 18.0927L11.455 20.4552C11.68 20.5677 11.905 20.6239 12.13 20.6239C12.5238 20.6239 12.9738 20.3989 13.1988 20.0052C13.5925 19.3864 13.3675 18.6552 12.805 18.2614L9.09252 15.8989C8.47377 15.5052 7.74252 15.7302 7.34877 16.2927C6.95502 16.9677 7.12377 17.7552 7.74252 18.0927Z"
                          fill="white"
                        />
                        <path
                          d="M5.04252 16.1802C5.43627 16.1802 5.88627 15.9552 6.11127 15.5614C6.50502 14.9427 6.28002 14.2114 5.71752 13.8177L4.81752 13.2552L5.71752 12.6927C6.33627 12.2989 6.50502 11.5114 6.11127 10.9489C5.71752 10.3302 4.93002 10.1614 4.36752 10.5552L1.72377 12.1864C1.33002 12.4114 1.10502 12.8052 1.10502 13.2552C1.10502 13.7052 1.33002 14.0989 1.72377 14.3239L4.36752 15.9552C4.53627 16.1239 4.76127 16.1802 5.04252 16.1802Z"
                          fill="white"
                        />
                        <path
                          d="M8.41752 10.7239C8.64252 10.7239 8.86752 10.6677 9.09252 10.5552L12.805 8.1927C13.4238 7.79895 13.5925 7.01145 13.1988 6.44895C12.805 5.8302 12.0175 5.66145 11.455 6.0552L7.74252 8.4177C7.12377 8.81145 6.95502 9.59895 7.34877 10.1614C7.57377 10.4989 7.96752 10.7239 8.41752 10.7239Z"
                          fill="white"
                        />
                        <path
                          d="M16.18 6.05522L18.1488 4.81772L20.1175 6.05522C20.3425 6.16772 20.5675 6.22397 20.7925 6.22397C21.1863 6.22397 21.6363 5.99897 21.8613 5.60522C22.255 4.98647 22.03 4.25522 21.4675 3.86147L18.8238 2.23022C18.43 1.94897 17.8675 1.94897 17.4738 2.23022L14.83 3.86147C14.2113 4.25522 14.0425 5.04272 14.4363 5.60522C14.83 6.16772 15.6175 6.44897 16.18 6.05522Z"
                          fill="white"
                        />
                        <path
                          d="M23.4925 8.19267L27.205 10.5552C27.43 10.6677 27.655 10.7239 27.88 10.7239C28.2738 10.7239 28.7238 10.4989 28.9488 10.1052C29.3425 9.48642 29.1175 8.75517 28.555 8.36142L24.8425 5.99892C24.28 5.60517 23.4925 5.83017 23.0988 6.39267C22.705 7.01142 22.8738 7.79892 23.4925 8.19267Z"
                          fill="white"
                        />
                        <path
                          d="M34.5738 12.1864L31.93 10.5552C31.3675 10.1614 30.58 10.3864 30.1863 10.9489C29.7925 11.5677 30.0175 12.2989 30.58 12.6927L31.48 13.2552L30.58 13.8177C29.9613 14.2114 29.7925 14.9989 30.1863 15.5614C30.4113 15.9552 30.8613 16.1802 31.255 16.1802C31.48 16.1802 31.705 16.1239 31.93 16.0114L34.5738 14.3802C34.9675 14.1552 35.1925 13.7614 35.1925 13.3114C35.1925 12.8614 34.9675 12.4114 34.5738 12.1864Z"
                          fill="white"
                        />
                        <path
                          d="M24.1675 20.624C24.3925 20.624 24.6175 20.5677 24.8425 20.4552L28.555 18.0927C29.1738 17.699 29.3425 16.9115 28.9488 16.349C28.555 15.7302 27.7675 15.5615 27.205 15.9552L23.4925 18.3177C22.8738 18.7115 22.705 19.499 23.0988 20.0615C23.3238 20.4552 23.7175 20.624 24.1675 20.624Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <h3 className="mb-3 text-xl font-bold text-dark">
                    Displayreparatur 
                    </h3>
                    <p className="mb-8 text-body-color lg:mb-9">
                    Behebung von Displayproblemen wie Rissen oder Pixelfehlern.

                    </p>
                    
                  </div>
                </div>
                <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                  <div
                    className="wow fadeInUp group mb-12 bg-white text-center"
                    data-wow-delay=".25s"
                  >
                    <div className="relative z-10 mb-10 flex h-[70px] w-[70px] items-center justify-center rounded-[14px] bg-red-800 mx-auto">
                      <span className="absolute top-0 left-0 -z-[1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-[14px] bg-red-800 bg-opacity-20 duration-300 group-hover:rotate-45"></span>
                      <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.355 2.0614H5.21129C3.29879 2.0614 1.72379 3.6364 1.72379 5.5489V12.6927C1.72379 14.6052 3.29879 16.1802 5.21129 16.1802H12.355C14.2675 16.1802 15.8425 14.6052 15.8425 12.6927V5.60515C15.8988 3.6364 14.3238 2.0614 12.355 2.0614ZM13.3675 12.7489C13.3675 13.3114 12.9175 13.7614 12.355 13.7614H5.21129C4.64879 13.7614 4.19879 13.3114 4.19879 12.7489V5.60515C4.19879 5.04265 4.64879 4.59265 5.21129 4.59265H12.355C12.9175 4.59265 13.3675 5.04265 13.3675 5.60515V12.7489Z"
                          fill="white"
                        />
                        <path
                          d="M31.0863 2.0614H23.9425C22.03 2.0614 20.455 3.6364 20.455 5.5489V12.6927C20.455 14.6052 22.03 16.1802 23.9425 16.1802H31.0863C32.9988 16.1802 34.5738 14.6052 34.5738 12.6927V5.60515C34.5738 3.6364 32.9988 2.0614 31.0863 2.0614ZM32.0988 12.7489C32.0988 13.3114 31.6488 13.7614 31.0863 13.7614H23.9425C23.38 13.7614 22.93 13.3114 22.93 12.7489V5.60515C22.93 5.04265 23.38 4.59265 23.9425 4.59265H31.0863C31.6488 4.59265 32.0988 5.04265 32.0988 5.60515V12.7489Z"
                          fill="white"
                        />
                        <path
                          d="M12.355 20.0051H5.21129C3.29879 20.0051 1.72379 21.5801 1.72379 23.4926V30.6364C1.72379 32.5489 3.29879 34.1239 5.21129 34.1239H12.355C14.2675 34.1239 15.8425 32.5489 15.8425 30.6364V23.5489C15.8988 21.5801 14.3238 20.0051 12.355 20.0051ZM13.3675 30.6926C13.3675 31.2551 12.9175 31.7051 12.355 31.7051H5.21129C4.64879 31.7051 4.19879 31.2551 4.19879 30.6926V23.5489C4.19879 22.9864 4.64879 22.5364 5.21129 22.5364H12.355C12.9175 22.5364 13.3675 22.9864 13.3675 23.5489V30.6926Z"
                          fill="white"
                        />
                        <path
                          d="M31.0863 20.0051H23.9425C22.03 20.0051 20.455 21.5801 20.455 23.4926V30.6364C20.455 32.5489 22.03 34.1239 23.9425 34.1239H31.0863C32.9988 34.1239 34.5738 32.5489 34.5738 30.6364V23.5489C34.5738 21.5801 32.9988 20.0051 31.0863 20.0051ZM32.0988 30.6926C32.0988 31.2551 31.6488 31.7051 31.0863 31.7051H23.9425C23.38 31.7051 22.93 31.2551 22.93 30.6926V23.5489C22.93 22.9864 23.38 22.5364 23.9425 22.5364H31.0863C31.6488 22.5364 32.0988 22.9864 32.0988 23.5489V30.6926Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <h3 className="mb-3 text-xl font-bold text-dark">
                    Festplattenbereinigung 
                    </h3>
                    <p className="mb-8 text-body-color lg:mb-9">
                    Optimierung des Speicherplatzes für eine reibungslose Leistung.
                    </p>
                    
                  </div>
                </div>
                <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                  <div
                    className="wow fadeInUp group mb-12 bg-white text-center"
                    data-wow-delay=".25s"
                  >
                    <div className="relative z-10 mb-10 flex h-[70px] w-[70px] items-center justify-center rounded-[14px] bg-red-800 mx-auto">
                      <span className="absolute top-0 left-0 -z-[1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-[14px] bg-red-800 bg-opacity-20 duration-300 group-hover:rotate-45"></span>
                      <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.355 2.0614H5.21129C3.29879 2.0614 1.72379 3.6364 1.72379 5.5489V12.6927C1.72379 14.6052 3.29879 16.1802 5.21129 16.1802H12.355C14.2675 16.1802 15.8425 14.6052 15.8425 12.6927V5.60515C15.8988 3.6364 14.3238 2.0614 12.355 2.0614ZM13.3675 12.7489C13.3675 13.3114 12.9175 13.7614 12.355 13.7614H5.21129C4.64879 13.7614 4.19879 13.3114 4.19879 12.7489V5.60515C4.19879 5.04265 4.64879 4.59265 5.21129 4.59265H12.355C12.9175 4.59265 13.3675 5.04265 13.3675 5.60515V12.7489Z"
                          fill="white"
                        />
                        <path
                          d="M31.0863 2.0614H23.9425C22.03 2.0614 20.455 3.6364 20.455 5.5489V12.6927C20.455 14.6052 22.03 16.1802 23.9425 16.1802H31.0863C32.9988 16.1802 34.5738 14.6052 34.5738 12.6927V5.60515C34.5738 3.6364 32.9988 2.0614 31.0863 2.0614ZM32.0988 12.7489C32.0988 13.3114 31.6488 13.7614 31.0863 13.7614H23.9425C23.38 13.7614 22.93 13.3114 22.93 12.7489V5.60515C22.93 5.04265 23.38 4.59265 23.9425 4.59265H31.0863C31.6488 4.59265 32.0988 5.04265 32.0988 5.60515V12.7489Z"
                          fill="white"
                        />
                        <path
                          d="M12.355 20.0051H5.21129C3.29879 20.0051 1.72379 21.5801 1.72379 23.4926V30.6364C1.72379 32.5489 3.29879 34.1239 5.21129 34.1239H12.355C14.2675 34.1239 15.8425 32.5489 15.8425 30.6364V23.5489C15.8988 21.5801 14.3238 20.0051 12.355 20.0051ZM13.3675 30.6926C13.3675 31.2551 12.9175 31.7051 12.355 31.7051H5.21129C4.64879 31.7051 4.19879 31.2551 4.19879 30.6926V23.5489C4.19879 22.9864 4.64879 22.5364 5.21129 22.5364H12.355C12.9175 22.5364 13.3675 22.9864 13.3675 23.5489V30.6926Z"
                          fill="white"
                        />
                        <path
                          d="M31.0863 20.0051H23.9425C22.03 20.0051 20.455 21.5801 20.455 23.4926V30.6364C20.455 32.5489 22.03 34.1239 23.9425 34.1239H31.0863C32.9988 34.1239 34.5738 32.5489 34.5738 30.6364V23.5489C34.5738 21.5801 32.9988 20.0051 31.0863 20.0051ZM32.0988 30.6926C32.0988 31.2551 31.6488 31.7051 31.0863 31.7051H23.9425C23.38 31.7051 22.93 31.2551 22.93 30.6926V23.5489C22.93 22.9864 23.38 22.5364 23.9425 22.5364H31.0863C31.6488 22.5364 32.0988 22.9864 32.0988 23.5489V30.6926Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <h3 className="mb-3 text-xl font-bold text-dark">
                    Tastaturreparatur  
                    </h3>
                    <p className="mb-8 text-body-color lg:mb-9">
                    Behebung von Tastaturproblemen, einschließlich defekter Tasten.

                    </p>
                    
                  </div>
                </div>
                <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                  <div
                    className="wow fadeInUp group mb-12 bg-white text-center"
                    data-wow-delay=".25s"
                  >
                    <div className="relative z-10 mb-10 flex h-[70px] w-[70px] items-center justify-center rounded-[14px] bg-red-800 mx-auto">
                      <span className="absolute top-0 left-0 -z-[1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-[14px] bg-red-800 bg-opacity-20 duration-300 group-hover:rotate-45"></span>
                      <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.355 2.0614H5.21129C3.29879 2.0614 1.72379 3.6364 1.72379 5.5489V12.6927C1.72379 14.6052 3.29879 16.1802 5.21129 16.1802H12.355C14.2675 16.1802 15.8425 14.6052 15.8425 12.6927V5.60515C15.8988 3.6364 14.3238 2.0614 12.355 2.0614ZM13.3675 12.7489C13.3675 13.3114 12.9175 13.7614 12.355 13.7614H5.21129C4.64879 13.7614 4.19879 13.3114 4.19879 12.7489V5.60515C4.19879 5.04265 4.64879 4.59265 5.21129 4.59265H12.355C12.9175 4.59265 13.3675 5.04265 13.3675 5.60515V12.7489Z"
                          fill="white"
                        />
                        <path
                          d="M31.0863 2.0614H23.9425C22.03 2.0614 20.455 3.6364 20.455 5.5489V12.6927C20.455 14.6052 22.03 16.1802 23.9425 16.1802H31.0863C32.9988 16.1802 34.5738 14.6052 34.5738 12.6927V5.60515C34.5738 3.6364 32.9988 2.0614 31.0863 2.0614ZM32.0988 12.7489C32.0988 13.3114 31.6488 13.7614 31.0863 13.7614H23.9425C23.38 13.7614 22.93 13.3114 22.93 12.7489V5.60515C22.93 5.04265 23.38 4.59265 23.9425 4.59265H31.0863C31.6488 4.59265 32.0988 5.04265 32.0988 5.60515V12.7489Z"
                          fill="white"
                        />
                        <path
                          d="M12.355 20.0051H5.21129C3.29879 20.0051 1.72379 21.5801 1.72379 23.4926V30.6364C1.72379 32.5489 3.29879 34.1239 5.21129 34.1239H12.355C14.2675 34.1239 15.8425 32.5489 15.8425 30.6364V23.5489C15.8988 21.5801 14.3238 20.0051 12.355 20.0051ZM13.3675 30.6926C13.3675 31.2551 12.9175 31.7051 12.355 31.7051H5.21129C4.64879 31.7051 4.19879 31.2551 4.19879 30.6926V23.5489C4.19879 22.9864 4.64879 22.5364 5.21129 22.5364H12.355C12.9175 22.5364 13.3675 22.9864 13.3675 23.5489V30.6926Z"
                          fill="white"
                        />
                        <path
                          d="M31.0863 20.0051H23.9425C22.03 20.0051 20.455 21.5801 20.455 23.4926V30.6364C20.455 32.5489 22.03 34.1239 23.9425 34.1239H31.0863C32.9988 34.1239 34.5738 32.5489 34.5738 30.6364V23.5489C34.5738 21.5801 32.9988 20.0051 31.0863 20.0051ZM32.0988 30.6926C32.0988 31.2551 31.6488 31.7051 31.0863 31.7051H23.9425C23.38 31.7051 22.93 31.2551 22.93 30.6926V23.5489C22.93 22.9864 23.38 22.5364 23.9425 22.5364H31.0863C31.6488 22.5364 32.0988 22.9864 32.0988 23.5489V30.6926Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <h3 className="mb-3 text-xl font-bold text-dark">
                    Lüfterreinigung  
                    </h3>
                    <p className="mb-8 text-body-color lg:mb-9">
                    Reinigung der Lüftungsschlitze für optimale Kühlung.

                    </p>
                    
                  </div>
                </div>
                <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                  <div
                    className="wow fadeInUp group mb-12 bg-white text-center"
                    data-wow-delay=".25s"
                  >
                    <div className="relative z-10 mb-10 flex h-[70px] w-[70px] items-center justify-center rounded-[14px] bg-red-800 mx-auto">
                      <span className="absolute top-0 left-0 -z-[1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-[14px] bg-red-800 bg-opacity-20 duration-300 group-hover:rotate-45"></span>
                      <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.355 2.0614H5.21129C3.29879 2.0614 1.72379 3.6364 1.72379 5.5489V12.6927C1.72379 14.6052 3.29879 16.1802 5.21129 16.1802H12.355C14.2675 16.1802 15.8425 14.6052 15.8425 12.6927V5.60515C15.8988 3.6364 14.3238 2.0614 12.355 2.0614ZM13.3675 12.7489C13.3675 13.3114 12.9175 13.7614 12.355 13.7614H5.21129C4.64879 13.7614 4.19879 13.3114 4.19879 12.7489V5.60515C4.19879 5.04265 4.64879 4.59265 5.21129 4.59265H12.355C12.9175 4.59265 13.3675 5.04265 13.3675 5.60515V12.7489Z"
                          fill="white"
                        />
                        <path
                          d="M31.0863 2.0614H23.9425C22.03 2.0614 20.455 3.6364 20.455 5.5489V12.6927C20.455 14.6052 22.03 16.1802 23.9425 16.1802H31.0863C32.9988 16.1802 34.5738 14.6052 34.5738 12.6927V5.60515C34.5738 3.6364 32.9988 2.0614 31.0863 2.0614ZM32.0988 12.7489C32.0988 13.3114 31.6488 13.7614 31.0863 13.7614H23.9425C23.38 13.7614 22.93 13.3114 22.93 12.7489V5.60515C22.93 5.04265 23.38 4.59265 23.9425 4.59265H31.0863C31.6488 4.59265 32.0988 5.04265 32.0988 5.60515V12.7489Z"
                          fill="white"
                        />
                        <path
                          d="M12.355 20.0051H5.21129C3.29879 20.0051 1.72379 21.5801 1.72379 23.4926V30.6364C1.72379 32.5489 3.29879 34.1239 5.21129 34.1239H12.355C14.2675 34.1239 15.8425 32.5489 15.8425 30.6364V23.5489C15.8988 21.5801 14.3238 20.0051 12.355 20.0051ZM13.3675 30.6926C13.3675 31.2551 12.9175 31.7051 12.355 31.7051H5.21129C4.64879 31.7051 4.19879 31.2551 4.19879 30.6926V23.5489C4.19879 22.9864 4.64879 22.5364 5.21129 22.5364H12.355C12.9175 22.5364 13.3675 22.9864 13.3675 23.5489V30.6926Z"
                          fill="white"
                        />
                        <path
                          d="M31.0863 20.0051H23.9425C22.03 20.0051 20.455 21.5801 20.455 23.4926V30.6364C20.455 32.5489 22.03 34.1239 23.9425 34.1239H31.0863C32.9988 34.1239 34.5738 32.5489 34.5738 30.6364V23.5489C34.5738 21.5801 32.9988 20.0051 31.0863 20.0051ZM32.0988 30.6926C32.0988 31.2551 31.6488 31.7051 31.0863 31.7051H23.9425C23.38 31.7051 22.93 31.2551 22.93 30.6926V23.5489C22.93 22.9864 23.38 22.5364 23.9425 22.5364H31.0863C31.6488 22.5364 32.0988 22.9864 32.0988 23.5489V30.6926Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <h3 className="mb-3 text-xl font-bold text-dark">
                    Datensicherung  
                    </h3>
                    <p className="mb-8 text-body-color lg:mb-9">
                    Sichere Kopie wichtiger Daten, um Verluste zu vermeiden.
                    </p>
                    
                  </div>
                </div>
                <div className="w-full px-4 md:w-1/2 lg:w-1/4">
                  <div
                    className="wow fadeInUp group mb-12 bg-white text-center"
                    data-wow-delay=".25s"
                  >
                    <div className="relative z-10 mb-10 flex h-[70px] w-[70px] items-center justify-center rounded-[14px] bg-red-800 mx-auto">
                      <span className="absolute top-0 left-0 -z-[1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-[14px] bg-red-800 bg-opacity-20 duration-300 group-hover:rotate-45"></span>
                      <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.355 2.0614H5.21129C3.29879 2.0614 1.72379 3.6364 1.72379 5.5489V12.6927C1.72379 14.6052 3.29879 16.1802 5.21129 16.1802H12.355C14.2675 16.1802 15.8425 14.6052 15.8425 12.6927V5.60515C15.8988 3.6364 14.3238 2.0614 12.355 2.0614ZM13.3675 12.7489C13.3675 13.3114 12.9175 13.7614 12.355 13.7614H5.21129C4.64879 13.7614 4.19879 13.3114 4.19879 12.7489V5.60515C4.19879 5.04265 4.64879 4.59265 5.21129 4.59265H12.355C12.9175 4.59265 13.3675 5.04265 13.3675 5.60515V12.7489Z"
                          fill="white"
                        />
                        <path
                          d="M31.0863 2.0614H23.9425C22.03 2.0614 20.455 3.6364 20.455 5.5489V12.6927C20.455 14.6052 22.03 16.1802 23.9425 16.1802H31.0863C32.9988 16.1802 34.5738 14.6052 34.5738 12.6927V5.60515C34.5738 3.6364 32.9988 2.0614 31.0863 2.0614ZM32.0988 12.7489C32.0988 13.3114 31.6488 13.7614 31.0863 13.7614H23.9425C23.38 13.7614 22.93 13.3114 22.93 12.7489V5.60515C22.93 5.04265 23.38 4.59265 23.9425 4.59265H31.0863C31.6488 4.59265 32.0988 5.04265 32.0988 5.60515V12.7489Z"
                          fill="white"
                        />
                        <path
                          d="M12.355 20.0051H5.21129C3.29879 20.0051 1.72379 21.5801 1.72379 23.4926V30.6364C1.72379 32.5489 3.29879 34.1239 5.21129 34.1239H12.355C14.2675 34.1239 15.8425 32.5489 15.8425 30.6364V23.5489C15.8988 21.5801 14.3238 20.0051 12.355 20.0051ZM13.3675 30.6926C13.3675 31.2551 12.9175 31.7051 12.355 31.7051H5.21129C4.64879 31.7051 4.19879 31.2551 4.19879 30.6926V23.5489C4.19879 22.9864 4.64879 22.5364 5.21129 22.5364H12.355C12.9175 22.5364 13.3675 22.9864 13.3675 23.5489V30.6926Z"
                          fill="white"
                        />
                        <path
                          d="M31.0863 20.0051H23.9425C22.03 20.0051 20.455 21.5801 20.455 23.4926V30.6364C20.455 32.5489 22.03 34.1239 23.9425 34.1239H31.0863C32.9988 34.1239 34.5738 32.5489 34.5738 30.6364V23.5489C34.5738 21.5801 32.9988 20.0051 31.0863 20.0051ZM32.0988 30.6926C32.0988 31.2551 31.6488 31.7051 31.0863 31.7051H23.9425C23.38 31.7051 22.93 31.2551 22.93 30.6926V23.5489C22.93 22.9864 23.38 22.5364 23.9425 22.5364H31.0863C31.6488 22.5364 32.0988 22.9864 32.0988 23.5489V30.6926Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <h3 className="mb-3 text-xl font-bold text-dark">
                    Flüssigkeitsschaden
                    </h3>
                    <p className="mb-8 text-body-color lg:mb-9">
                    Reparatur nach Kontakt mit Wasser oder anderen Flüssigkeiten.
                    </p>
                    
                  </div>
                </div>
              </div>
          {/* <!-- ====== Hero Section Start -->*/}
               {/* Third Section */}


      <section className="pb-20 bg-custom-gradient">
        <div className="container mx-auto px-4 py-4">
          <div className="flex flex-wrap">
            <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div className="px-4 py-5 flex-auto">
                  <div
                    className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-800"
                  >
                    <i className="fas fa-award"></i>

                  </div>
                  <h2 className="text-xl font-semibold">Sofortige Unterstützung</h2>
                  <p className="mt-2 mb-4 text-black">
                  Einfach vorbeikommen ermöglicht sofortige Unterstützung ohne Verzögerungen durch Online-Kommunikation.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-4/12 px-4 text-center">
              <div
                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div className="px-4 py-5 flex-auto">
                  <div
                    className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400"
                  >
                    <i className="fas fa-retweet"></i>
                  </div>
                  <h2 className="text-xl font-semibold">Persönliche Beratung</h2>
                  <p className="mt-2 mb-4 text-black">
                  Direkter Kontakt ermöglicht eine persönliche Beratung, maßgeschneidert für individuelle Bedürfnisse.
                  </p>
                </div>
              </div>
            </div>
            <div className="pt-6 w-full md:w-4/12 px-4 text-center">
              <div
                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
              >
                <div className="px-4 py-5 flex-auto">
                  <div
                    className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400"
                  >
                    <i className="fas fa-fingerprint"></i>
                  </div>
                  <h2 className="text-xl font-semibold">Schnelle Problembehebung</h2>
                  <p className="mt-2 mb-4 text-black">
                  Vor-Ort-Besuche ermöglichen eine schnellere Identifizierung und Lösung von Problemen ohne Wartezeiten.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap items-center mt-32">
            <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
              <div
                className="text-red-800 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100"
              >
                <i className="fas fa-user-friends text-xl"></i>
              </div>
              <h3 className="text-3xl mb-2 font-semibold leading-normal">
                Über uns             
              </h3>
              <p
                className="text-lg font-light leading-relaxed mt-4 mb-4 text-white"
              >
                Herzlich willkommen bei IdlibCom – Ihrem vertrauenswürdigen Partner für Computerreparaturen! Wir sind spezialisiert auf eine Vielzahl von Dienstleistungen, um sicherzustellen, dass Ihre Geräte stets optimal funktionieren. Unsere erfahrenen Techniker bieten Ihnen erstklassigen Service und maßgeschneiderte Lösungen für Ihre individuellen Bedürfnisse.
              </p>
              <p
                className="text-lg font-light leading-relaxed mt-0 mb-4 text-white"
              >
               
              </p>

            </div>
            <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
              <div
                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg "
              >
                <img
                  alt="..."
                  src="../../../img/home/section/ser1.jpg"
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full block h-[95px] -top-[94px]"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      className="text-red-800 fill-current"
                    ></polygon>
                  </svg>
                  <h4 className="text-xl font-bold text-black">
                    Top Notch Services
                  </h4>
                  <p className="text-md font-light mt-2 text-black">
                  erstklassige Dienstleistungen von höchster Qualität, indem modernste Technologie mit einem Fokus auf außergewöhnlicher Kundenzufriedenheit kombiniert wird. Unsere Hingabe zur Exzellenz gewährleistet eine überlegene Erfahrung in all unseren Services.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-[80px]"
            
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src="../../../img/home/tendo.jpg"
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-red-800 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-red-300">
                    <i className="fas fa-rocket text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">Wachsendes Unternehmen</h3>
                  <p className="mt-4 text-lg leading-relaxed text-black">
                  Unser wachsendes Unternehmen zeichnet sich durch Innovation, Kundenzentriertheit, Expertise, Nachhaltigkeit und globale Präsenz aus.
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-800 bg-red-200 mr-3">
                            <i className="fas fa-fingerprint"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-black">
                            Innovative Technologien
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-800 bg-red-200 mr-3">
                            <i className="fab fa-html5"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-black">
                              Kundenzentrierter Ansatz
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-red-800 bg-red-200 mr-3">
                            <i className="far fa-paper-plane"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-black">Expertise im Fokus</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <GeraeteRepariert />

    <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6 pb-20">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-sky-500">
        <path fillRule="evenodd" d="M2.25 13.5a8.25 8.25 0 018.25-8.25.75.75 0 01.75.75v6.75H18a.75.75 0 01.75.75 8.25 8.25 0 01-16.5 0z" clipRule="evenodd"></path>
        <path fillRule="evenodd" d="M12.75 3a.75.75 0 01.75-.75 8.25 8.25 0 018.25 8.25.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75V3z" clipRule="evenodd"></path>
      </svg>
      <div className="space-y-6 justify-between text-red-800 md:flex flex-row-reverse md:gap-6 md:space-y-0 lg:gap-12 lg:items-center">
        <div className="md:5/12 lg:w-1/2">
        <svg xmlns="http://www.w3.org/2000/svg"  height="402" viewBox="0 0 456 402" fill="none" className="w-full">
          <path d="M262.385 109.641H260.385V32.5977H405.042V34.5977H262.385V109.641Z" fill="#3F3D56"/>
          <path d="M427.225 55.56C442.568 55.56 455.005 43.1225 455.005 27.78C455.005 12.4375 442.568 0 427.225 0C411.883 0 399.445 12.4375 399.445 27.78C399.445 43.1225 411.883 55.56 427.225 55.56Z" fill="#01FEE6"/>
          <path d="M423.435 42.2376C422.956 42.2376 422.482 42.1259 422.053 41.9113C421.624 41.6967 421.251 41.3851 420.963 41.0013L413.385 30.896C413.141 30.5714 412.964 30.202 412.863 29.8089C412.763 29.4158 412.74 29.0067 412.798 28.605C412.855 28.2033 412.991 27.8168 413.198 27.4676C413.405 27.1184 413.678 26.8134 414.003 26.5699C414.327 26.3265 414.697 26.1493 415.09 26.0486C415.483 25.9479 415.892 25.9257 416.294 25.9831C416.696 26.0405 417.082 26.1764 417.431 26.3832C417.78 26.59 418.085 26.8635 418.329 27.1881L423.287 33.7987L436.022 14.6967C436.247 14.3587 436.536 14.0683 436.873 13.8421C437.211 13.6159 437.589 13.4585 437.987 13.3786C438.385 13.2988 438.795 13.2983 439.194 13.377C439.592 13.4557 439.971 13.6121 440.309 13.8374C440.647 14.0626 440.937 14.3522 441.163 14.6896C441.389 15.0271 441.546 15.4057 441.625 15.8039C441.705 16.2021 441.705 16.612 441.626 17.0103C441.547 17.4086 441.39 17.7874 441.164 18.125L426.007 40.8615C425.733 41.2726 425.364 41.6121 424.931 41.8514C424.499 42.0906 424.015 42.2227 423.521 42.2366C423.493 42.2371 423.464 42.2376 423.435 42.2376Z" fill="white"/>
          <path d="M164.385 296.686H162.385V373.729H307.042V371.729H164.385V296.686Z" fill="#3F3D56"/>
          <path d="M329.225 401.56C344.568 401.56 357.005 389.122 357.005 373.78C357.005 358.438 344.568 346 329.225 346C313.883 346 301.445 358.438 301.445 373.78C301.445 389.122 313.883 401.56 329.225 401.56Z" fill="#6C63FF"/>
          <path d="M325.435 388.238C324.956 388.238 324.482 388.126 324.053 387.911C323.624 387.697 323.251 387.385 322.963 387.001L315.385 376.896C315.141 376.571 314.964 376.202 314.863 375.809C314.763 375.416 314.74 375.007 314.798 374.605C314.855 374.203 314.991 373.817 315.198 373.468C315.405 373.118 315.678 372.813 316.003 372.57C316.327 372.327 316.697 372.149 317.09 372.049C317.483 371.948 317.892 371.926 318.294 371.983C318.696 372.04 319.082 372.176 319.431 372.383C319.78 372.59 320.085 372.863 320.329 373.188L325.287 379.799L338.022 360.697C338.247 360.359 338.536 360.068 338.873 359.842C339.211 359.616 339.589 359.458 339.987 359.379C340.385 359.299 340.795 359.298 341.194 359.377C341.592 359.456 341.971 359.612 342.309 359.837C342.647 360.063 342.937 360.352 343.163 360.69C343.389 361.027 343.546 361.406 343.625 361.804C343.705 362.202 343.705 362.612 343.626 363.01C343.547 363.409 343.39 363.787 343.164 364.125L328.007 386.861C327.733 387.273 327.364 387.612 326.931 387.851C326.499 388.091 326.015 388.223 325.521 388.237C325.493 388.237 325.464 388.238 325.435 388.238Z" fill="white"/>
          <path d="M83.6281 153.709C83.6114 138.677 89.3544 124.21 99.6764 113.282L64.2531 64.5269C62.7949 62.5182 60.921 60.8474 58.759 59.6281C56.597 58.4089 54.1976 57.6699 51.7242 57.4614C49.2509 57.253 46.7617 57.5799 44.4261 58.4201C42.0905 59.2602 39.9634 60.5938 38.1896 62.3301C26.055 74.159 16.4215 88.3059 9.86101 103.931C3.30056 119.555 -0.0529696 136.339 -0.000367198 153.285C-0.000268394 159.684 0.476815 166.075 1.42689 172.403C1.79961 174.905 2.71362 177.296 4.10518 179.408C5.49674 181.521 7.33236 183.304 9.48402 184.634C11.6114 185.951 14.0004 186.788 16.4844 187.086C18.9684 187.385 21.4876 187.138 23.8663 186.362L85.0319 166.488C84.101 162.292 83.6303 158.007 83.6281 153.709V153.709Z" fill="#F2F2F2"/>
          <path d="M86.8814 173.027L23.2916 193.689C20.9382 194.45 18.7758 195.707 16.951 197.377C15.1262 199.046 13.6816 201.089 12.715 203.365C11.7485 205.642 11.2826 208.1 11.349 210.572C11.4154 213.045 12.0124 215.474 13.0997 217.695C22.1554 236.067 35.5046 251.987 52.0171 264.106C68.5296 276.226 87.7191 284.188 107.961 287.319C110.426 287.698 112.944 287.539 115.342 286.853C117.74 286.167 119.962 284.971 121.854 283.346C123.744 281.726 125.261 279.715 126.3 277.453C127.34 275.191 127.877 272.731 127.876 270.242V210.849C118.458 208.406 109.789 203.675 102.638 197.077C95.4863 190.479 90.0742 182.218 86.8814 173.027Z" fill="#E6E6E6"/>
          <path d="M278.113 210.136C277.039 207.586 275.429 205.298 273.392 203.427C271.355 201.555 268.939 200.144 266.308 199.29L196.955 176.756C192.906 186.277 186.427 194.567 178.166 200.796C169.906 207.025 160.153 210.974 149.886 212.248V284.507C149.887 287.281 150.488 290.021 151.648 292.541C152.807 295.061 154.497 297.3 156.603 299.106C158.708 300.911 161.179 302.24 163.846 303.002C166.514 303.763 169.314 303.939 172.055 303.518C194.588 300.032 215.95 291.169 234.331 277.677C252.713 264.186 267.573 246.465 277.654 226.014C278.866 223.555 279.534 220.865 279.613 218.125C279.693 215.385 279.181 212.66 278.113 210.136Z" fill="#0E2F4E"/>
          <path d="M260.882 44.3944C258.907 42.4615 256.539 40.9768 253.939 40.0415C251.339 39.1062 248.568 38.7421 245.815 38.9742C243.062 39.2062 240.391 40.0289 237.984 41.3862C235.577 42.7435 233.491 44.6036 231.868 46.8397L184.453 112.101C189.908 117.569 194.232 124.058 197.179 131.197C200.126 138.336 201.638 145.986 201.628 153.709C201.628 155.146 201.559 156.566 201.458 157.977L276.827 182.466C279.492 183.326 282.312 183.595 285.092 183.254C287.871 182.913 290.543 181.97 292.921 180.491C295.298 179.011 297.325 177.032 298.86 174.689C300.394 172.347 301.399 169.698 301.806 166.927C302.864 159.882 303.395 152.768 303.395 145.644C303.453 126.78 299.72 108.096 292.417 90.7033C285.114 73.3102 274.39 57.5621 260.882 44.3944V44.3944Z" fill="#0DCBFD"/>
          <path d="M142.629 94.708C152.78 94.6965 162.76 97.3173 171.596 102.314L200.536 62.4819C202.011 60.4533 203.028 58.1288 203.518 55.6688C204.007 53.2087 203.957 50.6717 203.371 48.2329C202.803 45.8406 201.724 43.6 200.206 41.6656C198.688 39.7312 196.769 38.1493 194.58 37.0288C176.713 27.9419 156.956 23.1914 136.911 23.1622C116.865 23.1331 97.095 27.826 79.2012 36.8608C76.9446 37.9994 74.9625 39.615 73.3923 41.5957C71.8221 43.5763 70.7012 45.8747 70.1074 48.3315C69.5218 50.7533 69.4698 53.2734 69.9549 55.7173C70.44 58.1612 71.4508 60.4704 72.917 62.4848L105.747 107.672C116.2 99.2658 129.215 94.691 142.629 94.708V94.708Z" fill="#E6E6E6"/>
          </svg>  
        </div>
        <div className="md:7/12 lg:w-1/2">
          <h2 className="text-2xl font-bold text-gray-900 md:text-2xl dark:text-white">
          Kontaktieren Sie uns noch heute, um den Weg zu einer langfristig zuverlässigen und leistungsfähigen Nutzung Ihrer Appel und Windows-Geräte zu beginnen!
          </h2>
          <p className="my-8 text-black dark:text-gray-300">
          Unser Support-Team aus hochqualifizierten Technikern und Ingenieuren zeichnet sich durch blitzschnelle Reaktionszeiten aus, um sicherzustellen, dass Ihre Anliegen umgehend und kompetent bearbeitet werden.
         </p>
          <div className="divide-y space-y-4 divide-gray-100 dark:divide-gray-800">
            <div className="mt-8 flex gap-4 md:items-center">
              <div className="w-12 h-12 flex gap-4 rounded-full bg-indigo-100 dark:bg-indigo-900/20">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 m-auto text-indigo-500 dark:text-indigo-400">
                  <path fillRule="evenodd" d="M4.848 2.771A49.144 49.144 0 0112 2.25c2.43 0 4.817.178 7.152.52 1.978.292 3.348 2.024 3.348 3.97v6.02c0 1.946-1.37 3.678-3.348 3.97a48.901 48.901 0 01-3.476.383.39.39 0 00-.297.17l-2.755 4.133a.75.75 0 01-1.248 0l-2.755-4.133a.39.39 0 00-.297-.17 48.9 48.9 0 01-3.476-.384c-1.978-.29-3.348-2.024-3.348-3.97V6.741c0-1.946 1.37-3.68 3.348-3.97zM6.75 8.25a.75.75 0 01.75-.75h9a.75.75 0 010 1.5h-9a.75.75 0 01-.75-.75zm.75 2.25a.75.75 0 000 1.5H12a.75.75 0 000-1.5H7.5z" clipRule="evenodd"></path>
                </svg>        
              </div>
              <div className="w-5/6">
                <h3 className="font-semibold text-lg text-gray-700 dark:text-indigo-300">Schnelle Reaktionszeiten</h3>
                <p className="text-gray-500 dark:text-gray-400">Unser Support-Team zeichnet sich durch prompte Reaktionszeiten aus, um sicherzustellen, dass Ihre Anfragen und Anliegen sofort bearbeitet werden.</p>
              </div> 
            </div> 
            <div className="pt-4 flex gap-4 md:items-center">
              <div className="w-12 h-12 flex gap-4 rounded-full bg-teal-100 dark:bg-teal-900/20">  
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 m-auto text-teal-600 dark:text-teal-400">
                  <path fillRule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd"></path>
                </svg>                                      
              </div>
              <div className="w-5/6">
                <h3 className="font-semibold text-lg text-gray-700 dark:text-teal-300">Kundenorientierung</h3>
                <p className="text-gray-500 dark:text-gray-400">Wir sind stolz darauf, einen kundenorientierten Ansatz zu verfolgen, bei dem Ihre Bedürfnisse und Zufriedenheit immer im Mittelpunkt stehen.</p>
              </div> 
            </div> 
          </div>
        </div>
        </div>
        </div>
        <SalesSection />
        <Worksteps />
<FeatureSectionColorDesign 
    title='Unsere Exklusiven Funktionen' 
    feature={[
        {
            title: 'Anpassung',
            description: 'Passen Sie Ihre Computer und Mobilgeräte genau nach Ihren Wünschen an. Ob Hardware-Upgrades oder Software-Konfigurationen – wir sorgen dafür, dass Ihre Geräte Ihren individuellen Bedürfnissen entsprechen.'
        },
        {
            title: 'Leistung',
            description: 'Erleben Sie unvergleichliche Leistung mit unserer hochmodernen Technologie. Unsere Geräte sind darauf ausgelegt, Hochgeschwindigkeitsverarbeitung, nahtloses Multitasking und herausragende Benutzererfahrungen für all Ihre beruflichen und persönlichen Bedürfnisse zu bieten.'
        },
        {
            title: 'Sicherheit',
            description: 'Schützen Sie Ihre Daten und Geräte mit unseren fortschrittlichen Sicherheitslösungen. Von robuster Verschlüsselung bis hin zu sicheren Authentifizierungsmethoden – wir legen großen Wert auf Ihre Privatsphäre und schützen Ihre Informationen vor Bedrohungen.'
        },
        {
            title: 'Support',
            description: 'Profitieren Sie von unserem engagierten Support-Team, das rund um die Uhr für Sie da ist, um Ihnen bei Fragen oder Problemen zu helfen. Unsere Experten stehen Ihnen jederzeit zur Verfügung.'
        },
        {
            title: 'Skalierbarkeit',
            description: 'Unsere Lösungen wachsen mit Ihren Anforderungen. Egal, ob Sie Ihr Geschäft ausbauen oder Ihre Infrastruktur erweitern – unsere Geräte und Dienstleistungen sind darauf ausgelegt, mit Ihnen zu skalieren.'
        },
        {
            title: 'Zuverlässigkeit',
            description: 'Verlassen Sie sich auf die Zuverlässigkeit unserer Produkte. Wir bieten langlebige und robuste Geräte, die in jeder Situation zuverlässig funktionieren und Ihnen stets zur Seite stehen.'
        }
    ]} 
/>
 

            {/* ====== About Section End */}
         {/*<!-- Component: Testimonial carousel --> */}
                         {/*<!-- Component: One column even layout --> */}
      <section>
        <div className="container px-6 m-auto">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 md:col-span-8 lg:col-span-12">
             <img src="../../../img/home/reparaturbonus.jpg" alt="" className="m-auto"/>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- End One column even layout --> */}
      {/*<!-- Component: One column even layout --> */}
      <section className="my-6">
        <div className="container px-6 m-auto">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 md:col-span-8 lg:col-span-12">
          <p className="text-left leading-loose lg:text-xl text-black md:text-xl text-base">
         <span className="font-bold"> Der Reparaturbonus</span> des Klimaschutzministeriums fördert die Reparatur von Elektrogeräten in privaten Haushalten mit bis zu 50% der Kosten (max. 200 Euro für Reparaturen, 30 Euro für Kostenvoranschläge). IdlibCom ist Teil dieser Aktion! Ausgeschlossen sind Neukäufe und der Austausch gegen neue oder generalüberholte Geräte.            
         </p>
         </div>
          </div>
        </div>
                {/*<!-- add 2 button in center and responsive */}
                <div className="flex justify-center mt-6">
          <button type="submit"
          onClick={
            () => {
              window.open('https://www.reparaturbonus.at/', '_blank');
            }
          }
           className="bg-red-800 hover:bg-red-900 text-white font-bold py-2 px-4 rounded-xl mx-3">
            Mehr erfahren
           </button>
           <NavLink to="/contact">

          <div className="border border-red-800 hover:bg-red-700 hover:text-white font-bold py-2 px-4 rounded-xl">
            Termin vereinbaren
            </div>
            </NavLink>
        </div>
      </section>
      {/*<!-- End One column even layout --> */}
     
         <div className="py-20 bg-gray-1">
            <div className="container">
                <div className="wow fadeInUp" data-wow-delay=".2s">
                <div className="mb-12 text-center">
                    <h2 className="mb-4 text-3xl font-bold leading-tight text-dark sm:text-[40px] sm:leading-[1.2]">
                    <span className="text-red-800">Unsere</span> Kunden
                    </h2>
                    <p className="text-base leading-relaxed text-body-color">
                    Wir sind stolz darauf, dass wir seit über 10 Jahren eine große Anzahl an zufriedenen Kunden haben. Wir freuen uns, dass wir Ihnen helfen können, Ihre Appel und Windows-Geräte wieder zum Laufen zu bringen.
                    </p>
                </div>
                <CarouselTestimonial/>

                </div>
            </div>
         
            </div>

        <Cookiescomponent />

      <section>
        <div className="container px-6 m-auto pb-6">
          <div className="grid grid-cols-4  md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-6">
            <iframe
            className="rounded-r-xl"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d226.441999076286!2d16.3400069!3d48.2014163!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476d073c504cd4f9%3A0xa89c42655814d049!2sidlibcom%20handy%20Shop!5e1!3m2!1sen!2sat!4v1723840937071!5m2!1sen!2sat"
            width="100%"
            title="map1"
             height="450"
             style={{ border: '0' }}
             allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade">
            </iframe>
            </div>
            <div className="col-span-4 lg:col-span-6">
            <iframe src="https://www.google.com/maps/embed?pb=!4v1723841250625!6m8!1m7!1s-YKLr4N3aFyERFyn2q0JdQ!2m2!1d48.20148775354737!2d16.34056523952795!3f66.58237025143649!4f-8.869513832586122!5f1.1924812503605782"
             width="100%"
             title="map2"
             height="450"
             style={{ border: '0' }}
             allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade">
             </iframe>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- End Two columns even layout --> */}
 
      </div>

    );
  }
}


