import * as React from 'react';
import "./GeraeteRepariert.css";

export interface IGeraeteRepariertProps {
}

export interface IGeraeteRepariertState {
}

export default class GeraeteRepariert extends React.Component<IGeraeteRepariertProps, IGeraeteRepariertState> {
  constructor(props: IGeraeteRepariertProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
        <div className="row animation 0 animated fadeIn" data-animation="fadeIn" data-animation-delay="0s" 
        style= {{animationDelay: '0s', opacity: 1}}>
                              <div className="col-sm-3">
              <div className="experience-item  ">
                  <h5 className="experience-item-title">10</h5>
                  <div className="experience-item-text">Jahrelange Erfahrungen</div>
                              </div>
          </div>
                                  <div className="col-sm-3">
              <div className="experience-item  active">
                  <h5 className="experience-item-title">6000+</h5>
                  <div className="experience-item-text">Geräte repariert</div>
                                      <div className="experience-item-active">
                          <div className="tool-tip">Want to Fix Your?</div>
                          <img decoding="async" src="https://idlibcom.at/wp-content/themes/computer-repair/images/step-arrow.png" alt="" 
                          style={{display: 'block', width: '90%', height: 'auto'}}/>
  
                      </div>
                              </div>
          </div>
                                  <div className="col-sm-3">
              <div className="experience-item  ">
                  <h5 className="experience-item-title">50+</h5>
                  <div className="experience-item-text">Erfahrene Techniker</div>
                              </div>
          </div>
                                  <div className="col-sm-3">
              <div className="experience-item  ">
                  <h5 className="experience-item-title">100%</h5>
                  <div className="experience-item-text">Zufriedene Kunden</div>
                              </div>
          </div>
        </div>
    );
  }
}
