import axios from 'axios';

const getBusinessHours = async (placeId: string) => {
  const response = await axios.get(
    `https://www.IdlibCom.at/api/file_get_contents.php`
  );
 
  const openingHours = response.data
  if (!openingHours) {
    throw new Error('Opening hours not available');
  }
  return openingHours;
};

export default getBusinessHours;
