import * as React from 'react';
import Banner from '../Section/Banner';
import CarouselTestimonial from '../Section/CarouselTestimonial';
import FeatureHighlightsSection from '../Section/FeatureHighlightsSection';

export interface IAboutUsProps {
}

export interface IAboutUsState {
    currentIndex: number;
    carouselInterval?: NodeJS.Timeout | null; // Declare carouselInterval as a property

}

export default class AboutUs extends React.Component<IAboutUsProps, IAboutUsState> {
  constructor(props: IAboutUsProps) {
    super(props);
    this.state = {
        currentIndex: 0,
      };
    }
  
    componentDidMount() {
      this.startCarousel();
    }
  
    componentWillUnmount() {
      this.clearCarouselInterval();
    }
  
    startCarousel() {
      const carouselInterval = setInterval(() => {
        this.setState((prevState) => ({
          currentIndex: (prevState.currentIndex + 1) % this.logoImages.length,
        }));
      }, 4500);
  
      this.setState({ carouselInterval });
    }
  
    clearCarouselInterval() {
      const { carouselInterval } = this.state;
      if (carouselInterval) {
        clearInterval(carouselInterval);
      }
    }
  
    logoImages = [
      "https://Tailwindmix.b-cdn.net/carousel/logos/carousel-logo-image-1.svg",
      "https://Tailwindmix.b-cdn.net/carousel/logos/carousel-logo-image-2.svg",
      "https://Tailwindmix.b-cdn.net/carousel/logos/carousel-logo-image-3.svg",
      "https://Tailwindmix.b-cdn.net/carousel/logos/carousel-logo-image-4.svg",
      "https://Tailwindmix.b-cdn.net/carousel/logos/carousel-logo-image-5.svg",
      "https://Tailwindmix.b-cdn.net/carousel/logos/carousel-logo-image-6.svg",
    ];

  public render() {
    const { currentIndex } = this.state;

    return (
      <div className='mx-auto px-6 pb-6 lg:pb-10 lg:px-10       '>
        <Banner isHome={false} pageName="Über uns" color="white"/>

         <section id="about" className="bg-gray-1 pt-20 pb-8 lg:pt-[120px] lg:pb-[70px]">
    <div className="container">
      <div className="wow fadeInUp" data-wow-delay=".2s">
        <div className="-mx-4 flex flex-wrap items-center">
          <div className="w-full px-4 lg:w-1/2">
            <div className="mb-12 max-w-[540px] lg:mb-0">
              <h2 className="mb-5 text-3xl font-bold leading-tight text-dark sm:text-[40px] sm:leading-[1.2]">
              Willkommen bei IdlibCom
              </h2>
              <p className="mb-10 text-base leading-relaxed text-body-color">
              Bei IdlibCom sind wir leidenschaftlich in Bezug auf Technologie und engagieren uns dafür, Ihnen die besten Lösungen für Ihre Computer- und Mobilgerätebedürfnisse zu bieten. Unser Unternehmen, im Herzen von Wien, Österreich, gelegen, ist stolz darauf, erstklassige Dienstleistungen und Produkte zu liefern, die den höchsten Qualitäts- und Innovationsstandards entsprechen.
              </p>
            </div>
          </div>

          <div className="w-full px-4 lg:w-1/2">
            <div className="flex flex-wrap -mx-2 sm:-mx-4 lg:-mx-2 xl:-mx-4">
              <div className="w-full px-2 sm:w-1/2 sm:px-4 lg:px-2 xl:px-4">
                <div className="mb-4 sm:mb-8 sm:h-[400px] md:h-[540px] lg:h-[400px] xl:h-[500px]">
                  <img src="../../../img/aboutus/about-image-01.jpg" alt="about image2"
                    className="object-cover object-center w-full" />
                </div>
              </div>

              <div className="w-full px-2 sm:w-1/2 sm:px-4 lg:px-2 xl:px-4">
                <div className="mb-4 sm:mb-8 sm:h-[220px] md:h-[346px] lg:mb-4 lg:h-[225px] xl:mb-8 xl:h-[310px]">
                  <img src="../../../img/aboutus/about-image-02.jpg" alt="about image 1"
                    className="object-cover object-center w-full h-full" />
                </div>

                <div
                  className="bg-gray-400 relative z-10 mb-4 flex items-center justify-center overflow-hidden py-12 px-6 sm:mb-8 sm:h-[160px] sm:p-5 lg:mb-4 xl:mb-8">
                  <div>
                    <span className="block text-2xl text-center font-extrabold text-black">
                      Mehr als 16 jahre Erfahrung
                    </span>
              
                  </div>
                  <div>
                    <span className="absolute top-0 left-0 -z-10">
                      <svg width="106" height="144" viewBox="0 0 106 144" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.1" x="-67" y="47.127" width="113.378" height="131.304"
                          transform="rotate(-42.8643 -67 47.127)" fill="url(#paint0_linear_1416_214)" />
                        <defs>
                          <linearGradient id="paint0_linear_1416_214" x1="-10.3111" y1="47.127" x2="-10.3111"
                            y2="178.431" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                    <span className="absolute top-0 right-0 -z-10">
                      <svg width="130" height="97" viewBox="0 0 130 97" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.1" x="0.86792" y="-6.67725" width="155.563" height="140.614"
                          transform="rotate(-42.8643 0.86792 -6.67725)" fill="url(#paint0_linear_1416_215)" />
                        <defs>
                          <linearGradient id="paint0_linear_1416_215" x1="78.6495" y1="-6.67725" x2="78.6495"
                            y2="133.937" gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                    <span className="absolute bottom-0 right-0 -z-10">
                      <svg width="175" height="104" viewBox="0 0 175 104" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect opacity="0.1" x="175.011" y="108.611" width="101.246" height="148.179"
                          transform="rotate(137.136 175.011 108.611)" fill="url(#paint0_linear_1416_216)" />
                        <defs>
                          <linearGradient id="paint0_linear_1416_216" x1="225.634" y1="108.611" x2="225.634" y2="256.79"
                            gradientUnits="userSpaceOnUse">
                            <stop stopColor="white" />
                            <stop offset="1" stopColor="white" stopOpacity="0" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </span>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </section>
    <div className="container mx-auto px-4 py-4 bg-white shadow-lg rounded-lg">
<FeatureHighlightsSection
title="Warum Sie sich für uns entscheiden sollten?"
description="Wir bieten Ihnen eine Vielzahl von Dienstleistungen rund um Appel und Windows-Produkte an. Unsere erfahrenen Techniker sind darauf spezialisiert, Ihnen die bestmögliche Lösung für Ihre Bedürfnisse zu bieten. Wir legen großen Wert auf Qualität und Kundenzufriedenheit und sind stolz darauf, dass wir seit über 10 Jahren eine große Anzahl an zufriedenen Kunden haben."
features={[
  "Qualifizierte Techniker",
  "Schnelle Reparaturzeiten",
  "Original-Ersatzteile",
  "Kostenlose Beratung",
  "Individuelle Lösungen",
  "Mehrsprachiges Team",
  "Kundenorientierter Service",
  "Transparente Preise",
  "Umweltfreundliche Entsorgung",
]}
/>  
</div>
      {/*<!-- Component: One column even layout --> */}
      <section>
        <div className="container px-6 m-auto mt-3">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 md:col-span-8 lg:col-span-12">
              <h1 className="mb-4 text-3xl font-bold leading-tight text-dark sm:text-[40px] sm:leading-[1.2] text-center">
              Verkauf und Beratung
              </h1>
              <p className="mb-10 text-base font-bold leading-relaxed text-black text-center lg:px-[210px] lx:px-[210px]">
              Wir helfen Ihnen dabei, die richtigen Produkte für Ihre Bedürfnisse zu finden. Unser erfahrenes Team berät Sie umfassend und bietet maßgeschneiderte Lösungen, egal ob Sie ein neues Notebook, einen leistungsstarken Desktop-PC, ein Smartphone oder Zubehör suchen.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- End One column even layout --> */}
    {/* ====== About Section End */}

  <section id="about" className="pt-10 pb-8 lg:pb-[70px]">
    <div className="container">
      
      <div className="wow fadeInUp" data-wow-delay=".2s">
        <div className="-mx-4 flex flex-wrap items-center">
          <div className="w-full px-4 lg:w-1/2">
               {/*<!-- Component: Basic card --> */}
      <div className="overflow-hidden rounded-2xl text-white shadow-lg shadow-slate-100 lg:h-96 lg:pt-10  bg-black">
        <div className="p-10 ">
        <h3 className="mb-4 text-xl font-medium text-white text-center">
        Gerätewartung und Reparatur
        </h3>
          <p className='text-center leading-loose'>
          Unsere spezialisierten Techniker führen professionelle Wartungen und Reparaturen für Computer, Laptops, Smartphones und Tablets durch. Dazu gehören:
          </p>
          <ul className='text-center '>
            <li>Software- und Hardware-Reparaturen</li>
            <li>Systemoptimierung und -aufrüstung</li>
            <li>Datenrettung und -sicherung</li>
            <li>Display- und Akkutausch</li>
            </ul>
        </div>
      </div>
      {/*<!-- End Basic card --> */}
          </div>

          <div className="w-full px-4 lg:w-1/2">
                {/*<!-- Component: Basic card --> */}
      <div className="overflow-hidden  text-white shadow-lg shadow-slate-100 lg:h-96 lg:pt-10 rounded-2xl my-3  bg-black">
        <div className="p-6">
          <h3 className="mb-4 text-xl font-medium text-white text-center">
          Warum IdlibCom
        </h3>
          <p className='text-center leading-loose '>
          Expertise : Unsere Techniker sind zertifiziert und verfügen über langjährige Erfahrung im Bereich der Gerätewartung und -reparatur.
          </p>
          <p className='text-center leading-loose '>
          Kundenzufriedenheit : Wir legen großen Wert auf Qualität und Kundenservice und sind stets bemüht, all Ihren Wünschen und Anforderungen gerecht zu werden.
          </p>
          <p className='text-center leading-loose '>
          Individuelle Lösungen: : Wir bieten maßgeschneiderte Lösungen für Ihre Bedürfnisse und beraten Sie umfassend, um die besten Produkte und Dienstleistungen für Sie zu finden.
          </p>
        </div>
      </div>
      {/*<!-- End Basic card --> */}
            </div>
        </div>
        </div>
    </div>
    </section>
       {/* ====== CTA Section Start */}
  <section className="relative z-10 overflow-hidden bg-black  py-6 mb-3 rounded-2xl container m-auto">
    <div className="container mx-auto">
      <div className="relative overflow-hidden">
        <div className="flex flex-wrap items-stretch -mx-4">
          <div className="w-full px-4">
            <div className="mx-auto max-w-[570px] text-center">
              <h2 className="mb-2.5 text-lg font-bold md:leading-[1.44] text-white md:text-[28px]">
                <span>Kontaktieren Sie uns</span>
              </h2>
            

            </div>
            <div>
            <p className="mb-6 mx-auto text-base leading-[1.5] text-white w-full text-center container">
            Wenn Sie Fragen zu unseren Dienstleistungen haben oder Unterstützung benötigen, zögern Sie nicht, uns zu kontaktieren. Besuchen Sie uns in unserem Geschäft in Wien oder erreichen Sie uns online. Wir sind hier, um Ihnen zu helfen!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
 
    </div>
  </section>
<div className='
container mx-auto mb-3 px-6 m-auto lg:mt-10 mt-6'>
<h3 className='text-black text-center lg:text-2xl text-lg font-bold'>
        Herzlich willkommen bei IdlibCom – wo Qualität und Kundenservice an erster Stelle stehen.
        </h3>
</div>
  {/* ====== CTA Section End */}
    {/* ====== About Section End */}
         <div className="py-20 bg-gray-1">
            <div className="container">
                <div className="wow fadeInUp" data-wow-delay=".2s">
                <div className="mb-12 text-center">
                    <h2 className="mb-4 text-3xl font-bold leading-tight text-dark sm:text-[40px] sm:leading-[1.2]">
                    <span className="text-primary">Unsere</span> Kunden
                    </h2>
                    <p className="text-base leading-relaxed text-body-color">
                    Wir sind stolz auf die positiven Bewertungen und das Feedback, das wir von unseren Kunden erhalten haben. Hier sind einige Beispiele dafür, was unsere Kunden über uns sagen.                    
                    </p>
                </div>
               <CarouselTestimonial/> 

                </div>
            </div>
         
            </div>
        
      </div>
    );
    }
}

    
    

