import React, { Component } from 'react';

class Impressum extends Component {
  render() {
    return (
      <div className="pt-40 pb-20  container">
        <h1 className="text-2xl font-bold mb-4">IdlibCom</h1>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">Inhaber:</h2>
          <p>Ahmed Anadani </p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">Address:</h2>
          <p>Kaiserstrasse 48</p>
          <p>1070 Wien</p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">Contact:</h2>
          <p>Telefon: <a href="tel:+4368120231766" className="text-blue-500 underline">+4368120231766</a></p>
          <p>Email: <a href="mailto:info@idlibcom.at" className="text-blue-500 underline">info@idlibcom.at</a></p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">UID-Nr.:</h2>
          <p>ATU78214406</p>
        </div>
        <div className="mb-6">
          <h2 className="text-xl font-semibold">GISA-Zahl:</h2>
          <p>31870023</p>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold">LI Mechatroniker:</h2>
          <p>
          Gewerbewortlaut Mechatroniker für Elektronik, Büro- und EDV-Systemtechnik, eingeschränkt auf Service und Reparatur von Smartphones, Tablets, PC´s und Laptops mittels vorgefertigter Komponenten
          </p>
        </div>
      </div>
    );
  }
}

export default Impressum;
