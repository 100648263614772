import * as React from "react";
import Header from "./Layaout/Header";
import Main from "./Layaout/Main";
import Footer from "./Layaout/Footer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import TopHeader from "./Layaout/TopHeader";

export interface IWebAppProps {}

export interface IWebAppState {
  pageName: string;
}

export default class WebApp extends React.Component<
  IWebAppProps,
  IWebAppState
> {
  constructor(props: IWebAppProps) {
    super(props);

    this.state = {
      pageName: "Home",
    };
  }

  public render() {
    return (
      <BrowserRouter>

      <div className="h-full">

      <div className="">
          <Header/>
        </div>
        <div className="">
          <Main/>
               </div>
        <div className="">
        <Footer />
        </div>
    
      </div>
      </BrowserRouter>
    );
  }
}


