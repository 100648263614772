import * as React from 'react';
import ServicesList from '../Section/ServicesList';
import Banner from '../Section/Banner';

export interface IRequestFormProps {
}

export interface IRequestFormState {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    message: string;    
    address: string;
    ort: string;
    plz: string;    
    succesMsg: boolean;


}

export default class RequestForm extends React.Component<IRequestFormProps, IRequestFormState> {
  constructor(props: IRequestFormProps) {
    super(props);

    this.state = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        message: '',
        address: '',
        ort: '',
        plz: '',
        succesMsg: false,
      };
      this.handleSendEmail = this.handleSendEmail.bind(this);

  }

 
  
  private handleSendEmail(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();
    const { firstName, lastName, phoneNumber, email, message, address, ort, plz } = this.state;

    const data = { firstName, lastName, phoneNumber, email, message, address, ort, plz };
    
    fetch('https://www.IdlibCom.at/api/send_email_contact.php', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          this.setState({
            firstName: '',
            lastName: '',
            phoneNumber: '',
            email: '',
            message: '',
            address: '',
            ort: '',
            plz: '',
            succesMsg: true,
          });
          window.scrollTo(0, 0);
        }
      })
      .catch((error) => console.error('Error:', error));
  }
  
    componentDidUpdate(_: IRequestFormProps, prevState: IRequestFormState): void {
        if (this.state.succesMsg && !prevState.succesMsg) {
          setTimeout(() => this.setState({ succesMsg: false }), 5000);
        }
      }
      
  

    render() {
      return (
        <div>
          <div>
            <h1 className="lg:text-3xl xl:text-3xl text-center font-bold mt-8 uppercase text-base px-1">
              Kontaktieren Sie uns für strahlende Energie: Wir sind für Sie da!
            </h1>
          </div>
          {this.state.succesMsg && <p className="text-center text-green-500">Nachricht erfolgreich gesendet!</p>}
          <form className="w-full max-w-3xl mx-auto mt-8">
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-first-name">
                  Vorname *
                </label>
                <input
                  value={this.state.firstName}
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                  autoComplete="off"
                  role="presentation"
                  className="sm:mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-first-name"
                  type="text"
                />
              </div>
              {/* Add other fields with `value` attributes */}
              <div className="w-full md:w-1/2 px-3">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-last-name">
                  Nachname *
                </label>
                <input
                  value={this.state.lastName}
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                  autoComplete="off"
                  role="presentation"
                  className="sm:mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="grid-last-name"
                  type="text"
                />
                  </div>
  
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-phone-number">
                      Telefonnummer *
                  </label>
                  <input
                      value={this.state.phoneNumber}
                      onChange={(e) => this.setState({ phoneNumber: e.target.value })}
                      autoComplete="off"
                      role="presentation"
                      className="sm:mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-phone-number"
                      type="text"
                  />
                  </div>
              <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-email">
                      Email *
                  </label>
                  <input
                      value={this.state.email}
                      onChange={(e) => this.setState({ email: e.target.value })}
                      autoComplete="off"
                      role="presentation"
                      className="sm:mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-email"
                      type="email"
                  />
                  </div>
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-address">
                      Adresse *
                  </label>
                  <input
                      value={this.state.address}
                      onChange={(e) => this.setState({ address: e.target.value })}
                      autoComplete="off"
                      role="presentation"
                      className="sm:mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-address"
                      type="text"
                  />
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-ort">
                      Ort *
                  </label>
                  <input
                      value={this.state.ort}
                      onChange={(e) => this.setState({ ort: e.target.value })}
                      autoComplete="off"
                      role="presentation"
                      className="sm:mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-ort"
                      type="text"
                  />
                  </div>
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-plz">
                      PLZ *
                  </label>
                  <input
                      value={this.state.plz}
                      onChange={(e) => this.setState({ plz: e.target.value })}
                      autoComplete="off"
                      role="presentation"
                      className="sm:mx-2 appearance-none block w-[95%] bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-plz"
                      type="text"
                  />
                  </div>
                  <div className="w-full px-3">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 pl-3" htmlFor="grid-message">
                      Nachricht *
                  </label>
                  <textarea
                      value={this.state.message}
                      onChange={(e) => this.setState({ message: e.target.value })}
                      autoComplete="off"
                      role="presentation"
                      className="sm:mx-2 appearance-none block w-full bg-gray-50 text-gray-700 border border-gray-200 rounded-lg py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                      id="grid-message"
                      rows={6}
                  />
                  </div>
  
            </div>
            <div className="md:flex md:items-center">
              <div className="w-full p-6">
                <button
                  disabled={
                    !this.state.firstName ||
                    !this.state.lastName ||
                    !this.state.phoneNumber ||
                    !this.state.email ||
                    !this.state.address ||
                    !this.state.ort ||
                    !this.state.plz ||
                    !this.state.message
                  }
                  onClick={this.handleSendEmail}
                  className="shadow-lg w-full bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
                  type="button"
                >
                  Senden
                </button>
              </div>
            </div>
          </form>
        </div>
      );
    }
  }
  