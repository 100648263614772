import * as React from 'react';

export interface IWorkstepsProps {
}

export interface IWorkstepsState {
}

export default class Worksteps extends React.Component<IWorkstepsProps, IWorkstepsState> {
  constructor(props: IWorkstepsProps) {
    super(props);

    this.state = {
    }
  }

  public render() {
    return (
      <div>
           <section>
        <div className="container px-6 m-auto items-center ">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-3 h-96">
                <img className='mt-[12%] mb-auto' src="../../../img/Worksteps/w1.png" alt="" />
            </div>
            <div className="col-span-4 lg:col-span-6  lg:h-72 bg-[#961f1f] rounded-lg my-auto">
                <h1 className='text-4xl font-bold text-white text-center mt-6'>Unsere Arbeitsschritte</h1>
                <p className='text-center text-[#ffffff] lg:mt-2 py-3 lg:py-1 lg:px-1'>
                Wechseln Sie zu zuverlässiger Technik mit IdlibCom-Wartung! Sichern Sie die Zukunft Ihrer Geräte und sparen Sie langfristig an Reparaturkosten. Unsere erstklassigen Wartungs- und Reparaturservices für Appel und Windows- und Android-Produkte garantieren nicht nur eine längere Lebensdauer Ihrer Geräte, sondern stellen auch sicher, dass sie stets in Top-Zustand bleiben. Verabschieden Sie sich von technischen Problemen und ineffizienter Leistung. Mit der IdlibCom-Wartung arbeiten Ihr Smartphone, Ihr Tablet und Ihr Laptop immer effizient und leistungsfähig. Treffen Sie heute die Entscheidung für eine nachhaltige Leistungsfähigkeit Ihrer Geräte!
                  </p>
            </div>
            <div className="col-span-4 lg:col-span-3  h-96">
            <img className='mt-[12%] mb-auto' src="../../../img/Worksteps/w2.png" alt="" />

            </div>

          </div>
        </div>
        <div>
            <h1 className='text-4xl font-bold text-center mt-3'>Schritte zum Reparieren Ihres mobilen Geräts</h1>
            
        </div>
      </section>
         <section className='mt-6'>
        <div className="container px-6 m-auto">
          <div className="grid grid-cols-4 gap-6 md:grid-cols-8 lg:grid-cols-12">
            <div className="col-span-4 lg:col-span-3 h-96">
                <img className=' mb-auto' src="../../../img/Worksteps/work1.png" alt="" />
                <p>
                <span className='text-[#002366] font-bold'>1. </span>
                <span className='text-[#002366]'>Anfrage</span>
                </p>
            </div>
            <div className="col-span-4 lg:col-span-3 h-96">
                <img className=' mb-auto' src="../../../img/Worksteps/work2.png" alt="" />
                <p>
                <span className='text-[#002366] font-bold'>2. </span>
                <span className='text-[#002366]'>Bestätigung und Preise</span>
                </p>
            </div>
            <div className="col-span-4 lg:col-span-3 h-96">
                <img className=' mb-auto' src="../../../img/Worksteps/work3.png" alt="" />
                <p>
                <span className='text-[#002366] font-bold'>3. </span>
                <span className='text-[#002366]'>Techniker arbeitet am Gerät</span>
                </p>
            </div>
            <div className="col-span-4 lg:col-span-3 h-96">
                <img className=' mb-auto' src="../../../img/Worksteps/work4.png" alt="" />
                <p>
                <span className='text-[#002366] font-bold'>4. </span>
                <span className='text-[#002366]'>Erhalten Sie das reparierte Gerät</span>
                </p>
            </div>
          </div>
        </div>
      </section>
      </div>
    );
  }
}
