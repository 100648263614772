import React from 'react';
import styles from './SalesSection.module.css';  // Import the CSS module
import { NavLink } from 'react-router-dom';

const SalesSection: React.FC = () => {
  return (
    <div className={`${styles.container} flex flex-col md:flex-row items-center justify-center`}>
      {/* Left Side - Image */}
      <div className={`${styles.imageContainer} relative`}>
        <img
          src="../../../img/smiling.jpg"
          alt="Smiling Woman"
          className={`${styles.skewedImage} transform skew-x-6`}
        />
      </div>

      {/* Right Side - Text Content */}
      <div className={`${styles.textContent} md:w-1/2 w-full`}>
        <h2 className={styles.title}>Kaufen, verkaufen, handeln</h2>
        <p className={styles.subtitle}>
          Sie möchten Ihr Telefon nicht reparieren? Willst du nur das neueste Gadget?
        </p>
        <p className={styles.description}>
          Wir haben viele Möglichkeiten, Sie wieder fit zu machen. Nur weil Sie einen
          Servicevertrag mit Ihrem Mobilfunkanbieter abgeschlossen haben, heißt das nicht, dass
          Sie ein Telefon behalten müssen, mit dem Sie nicht mehr zufrieden sind.
        </p>
        <ul className={styles.markerList}>
          <li>
            <span>✔</span>
            Wir geben Ihnen Bargeld für Ihr gebrauchtes oder kaputtes Gerät.
          </li>
          <li>
            <span>✔</span>
            Wir kommen zu Ihnen, holen Ihr Gerät ab und überreichen Ihnen Bargeld.
          </li>
          <li>
            <span>✔</span>
            Lehnen Sie sich zurück und entspannen Sie sich. Genießen Sie unseren Service ganz nach
            Ihren Wünschen.
          </li>
        </ul>
        <div className={styles.buttonContainer}>
          <NavLink to="/services" 
            className={styles.actionButton}
          >
            Verkaufe gebrauchtes Gerät <i className="ml-2">►</i>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default SalesSection;