// GoogleReviewsComponent.tsx

import React, { Component } from "react";
import axios from "axios";
import Banner from "./Banner";

interface GoogleReviewsState {
  reviews: any[]; // Define the structure of the reviews
}

class GoogleReviewsComponent extends Component<{}, GoogleReviewsState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      reviews: [],
    };
  }

  componentDidMount() {
    this.fetchGoogleReviews();
  }

  fetchGoogleReviews = () => {
    // Make a request to the Google Places API
    axios
      .get(`https://www.IdlibCom.at/api/GoogleReviewsComponent.php`)
      .then((response) => {
        this.setState({ reviews: response.data });
      })
      .catch((error) => {
        console.error("Error fetching Google Reviews:", error);
      });
  };

  render() {
    return (
      <div>
        <Banner isHome={false} pageName="Kontakt" color="violet" />
        <div className="container mx-auto">
          <h1 className="text-3xl font-bold text-center">Google Reviews</h1>
          <div className="flex justify-center">
            <div className="w-1/2">
              <ul
                className="list-none p-0 m-0 bg-white rounded-2xl shadow-2xl"
              >
                {this.state.reviews.map((review) => (
                  <li
                    className="p-4 m-4 bg-white rounded-2xl shadow-2xl"
                    key={review.time}
                  >
                    <p>{review.text}</p>
                    <p>Rating: {review.rating}</p>

                    <p>Author: {review.author_name}</p>
                    <p>Time: {review.relative_time_description}</p>
                    <img
                      src={review.profile_photo_url}
                      alt={review.author_name}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GoogleReviewsComponent;
